import React from 'react';
import styled from 'styled-components';

interface Props {
  icon: string;
  width: number;
  height: number;
  alt?: string;
  isSelected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const IconBarIcon = ({ icon, width, height, alt, isSelected = false, onClick, ...rest }: Props) => {
  return (
    <Button type="button" isSelected={isSelected} onClick={onClick} {...rest}>
      <img src={icon} width={width} height={height} alt={alt || ''} />
    </Button>
  );
};

interface ButtonProps {
  isSelected: boolean;
}

const Button = styled.button<ButtonProps>`
  appearance: none;
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  padding: 0 5px;

  ${props => (props.isSelected ? 'box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -1px -1px #dfdfdf, inset 1px 1px #808080;' : `
    &:hover,
    &:focus {
      border-color: #fff;
      box-shadow: 1px 1px 1px 0px #a0a0a0, 2px 2px 1px 0px #a0a0a0
    }
  `)}


`;

export default IconBarIcon;
