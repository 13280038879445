import { Extension } from "@tiptap/core";
import "@tiptap/extension-text-style";

const Color = Extension.create({
  name: "color",

  defaultOptions: {
    types: ["textStyle"],
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            parseHTML: (element) => element.style.color.replace(/['"]+/g, ""),
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {};
              }

              return {
                style: `color: ${attributes.color}`,
              };
            },
          },
          backgroundColor: {
            default: null,
            parseHTML: (element) =>
              element.style.backgroundColor.replace(/['"]+/g, ""),
            renderHTML: (attributes) => {
              if (!attributes.backgroundColor) {
                return {};
              }

              return {
                style: `background-color: ${attributes.backgroundColor}`,
              };
            },
          },
          fontFamily: {
            default: null,
            parseHTML: (element) =>
              element.style.fontFamily.replace(/['"]+/g, ""),
            renderHTML: (attributes) => {
              if (
                !attributes.fontFamily ||
                attributes.fontFamily === "Times New Roman"
              ) {
                return {};
              }
              return {
                style: `font-family: ${attributes.fontFamily}`,
              };
            },
          },
          fontSize: {
            default: null,
            parseHTML: (element) =>
              element.style.fontSize.replace(/['"]+/g, ""),
            renderHTML: (attributes) => {
              if (!attributes.fontSize || attributes.fontSize === 16) {
                return {};
              }
              return {
                style: `font-size: ${attributes.fontSize
                  .toString()
                  .replace("px", "")}px`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setColor:
        (color, bgColor, fontFamily, fontSize) =>
        ({ chain }) => {
          return chain()
            .setMark("textStyle", {
              color,
              backgroundColor: bgColor,
              fontFamily,
              fontSize,
            })
            .run();
        },
      unsetColor:
        () =>
        ({ chain }) => {
          return chain()
            .setMark("textStyle", { color: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});

export default Color;
