import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useDrag from "../useDrag";
import styled, { css, CSSProperties } from "styled-components";
import ContextMenu from "./ContextMenu";
import { WindowManagerContext } from "../react-window-manager";

interface SingleTopNavOption {
  name: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  showMenu?: boolean;
  setShowContextMenu?: Function;
}

interface TopNavOption extends SingleTopNavOption {
  items?: SingleTopNavOption[][];
}

interface Props {
  children: React.ReactNode;
  onClose?: Function;
  width?: number;
  navActionList?: ("minimize" | "restore" | "maximize" | "close")[];
  topNavOptions?: TopNavOption[];
  height?: number;
  icon?: string;
  title: string;
}

interface Window {
  title: string;
  icon?: string;
  width?: number;
  height?: number;
  isActive?: boolean;
}

const Window = ({
  children,
  onClose,
  width,
  navActionList = ["minimize", "restore", "maximize", "close"],
  topNavOptions,
  height,
  icon,
  title,
}: Props) => {
  const {
    dragEventHandlers,
    transformStyles,
    isDragging,
    setIsDraggingEnabled,
  } = useDrag();

  const [status, setStatus] = useState("restore");

  // to prevent stale state in the handleTaskBarClick event handler
  const statusRef = useRef(status);

  const handleTaskBarClick = useCallback(
    (statusRef: any, isActive: boolean, windowId: string) => {
      if (statusRef.current === "minimize") {
        setStatus("restore");
        focusWindowById(windowId, true, "restore");
      } else if (
        statusRef.current === "maximize" ||
        statusRef.current === "restore"
      ) {
        if (isActive) {
          setStatus("minimize");
          focusWindowById(windowId, isActive, "minimize");
        } else {
          focusWindowById(windowId, true, "minimize");
        }
      }
    },
    [status]
  );

  const { useWindowRef, sortedOrder, focusWindowById } =
    useContext<any>(WindowManagerContext);
  const { windowRef, windowId } = useWindowRef({
    title,
    isActive: false,
    onClick: (isActive: boolean) => {
      return handleTaskBarClick(statusRef, isActive, windowId);
    },
    icon,
  });

  const closeWindow = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (status === "close") {
      closeWindow();
    } else if (status === "minimize") {
      focusWindowById(windowId, false, "minimize");
    }
    if (status === "maximize") {
      setIsDraggingEnabled(false);
    } else {
      setIsDraggingEnabled(true);
    }

    statusRef.current = status;
  }, [status]);

  const getWindowDimensions = (): CSSProperties => {
    if (!isDragging && status === "maximize") {
      return {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "calc(100% - 26px)",
        transform: "none",
      };
    }

    return { position: "absolute", width };
  };

  return (
    <>
      <WindowElement
        style={{
          ...transformStyles(),
          ...getWindowDimensions(),
          display: status === "close" || status === "minimize" ? "none" : "",
          zIndex: sortedOrder.findIndex(
            ({ id }: { id: string }) => id === windowId
          ),
        }}
        ref={windowRef}
        className={
          // sortedOrder[sortedOrder.length - 1]?.id === windowId &&
          sortedOrder.find(({ id }: { id: string }) => id === windowId)
            ?.isActive
            ? "is-focused"
            : ""
        }
        data-testid={`${title} Window`}
        aria-hidden={sortedOrder[sortedOrder.length - 1]?.id !== windowId}
      >
        <Header
          {...dragEventHandlers}
          onDoubleClick={(e) => {
            if (status === "maximize" || status === "restore") {
              status === "maximize"
                ? setStatus("restore")
                : setStatus("maximize");
            }
          }}
        >
          <Nav>
            <NavList>
              <NavButtonWrap>
                <ResetButton>
                  <StaticNavList>
                    {icon && (
                      <NavListItem style={{ overflow: "visible" }}>
                        <img src={icon} alt="" width="16" height="16" />
                      </NavListItem>
                    )}
                    <NavListItem>
                      <TitleText>{title}</TitleText>
                    </NavListItem>
                  </StaticNavList>
                </ResetButton>
              </NavButtonWrap>
              <NavActionButtons>
                {navActionList.includes("minimize") && (
                  <NavListMinimizeButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setStatus("minimize");
                    }}
                    aria-label={`Minimize ${title} Window`}
                  />
                )}
                {navActionList.includes("restore") && status === "maximize" ? (
                  <NavListRestoreButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setStatus("restore");
                    }}
                  />
                ) : navActionList.includes("maximize") ? (
                  <NavListMaximizeButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setStatus("maximize");
                    }}
                  />
                ) : (
                  <div />
                )}
                {navActionList.includes("close") && (
                  <NavListCloseButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setStatus("close");
                    }}
                    aria-label={`Close ${title} Window`}
                  />
                )}
              </NavActionButtons>
            </NavList>
          </Nav>
        </Header>
        {topNavOptions?.length && (
          <TopNav>
            <TopNavList>
              {topNavOptions.map((option: any) => (
                <TopNavListItem key={option.name}>
                  <TopNavButton onClick={option.onClick}>
                    {option.name}
                  </TopNavButton>
                  {option.items && option.showMenu && (
                    <ContextMenu
                      isTopNav
                      linkSections={option.items}
                      setShowContextMenu={option.setShowContextMenu}
                    />
                  )}
                </TopNavListItem>
              ))}
            </TopNavList>
          </TopNav>
        )}
        {children}
      </WindowElement>
    </>
  );
};

const TopNavButton = styled.button`
  appearance: none;
  margin: 0;
  padding: 5px 7px;
  background-color: transparent;
  font: inherit;

  &:focus {
    box-shadow: inset -1px -1px 0px #ffffff, inset 1px 1px 0px #0c0c0c,
      inset -2px -2px 0px #bbc3c4, inset 2px 2px 0px #808088;
  }
`;
const TopNav = styled.nav``;
const TopNavList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
`;
const TopNavListItem = styled.li`
  position: relative;
`;

const NavList = styled.ul`
  font-size: 11px;
  display: flex;
  align-items: center;
  height: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  background: linear-gradient(to right, #8c8c8c, #ababab);
  font-weight: bold;
  color: #ababab;
  margin-bottom: 1px;
  padding: 0px 1px 0px 3px;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 1px;
`;

const WindowElement = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px;
  background-color: #c0c0c0;
  box-shadow: inset -1px -1px 0px #0c0c0c, inset 1px 1px 0px #bbc3c4,
    inset -2px -2px 0px #808088, inset 2px 2px 0px #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 11px;

  &.is-focused {
    position: relative;
    z-index: 1;
    ${NavList} {
      background: linear-gradient(to right, #0000a2, #126fc2);
      color: #fff;
    }
  }
`;
const TitleText = styled.span`
  margin-left: 3px;
`;
const Header = styled.header``;

const NavActionButtons = styled.li`
  display: flex;
`;

const NavButtonWrap = styled.li`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
`;
const StaticNavList = styled.ul`
  display: flex;
  padding: 0;
  align-items: center;
`;
const Nav = styled.nav`
  background: #c0c0c0;
`;
const NavListItem = styled.li`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const NavIcon = styled.img`
  display: inline-block;
  margin-left: 1px;
  margin-top: 2px;
  margin-right: 3px;
`;
const SharedNavListButtonStyles = css`
  background-color: #bbc3c4;
  position: relative;
  user-select: none;
  box-shadow: inset -1px -1px 0px #0c0c0c, inset 1px 1px 0px #ffffff,
    inset -2px -2px 0px #808088, inset 2px 2px 0px #bbc3c4;
  padding: 0px;
  min-width: initial;
  width: 16px;
  height: 14px;
  margin-left: 1px;
  image-rendering: pixelated;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: 1px 1px;
  outline: 0;
  border: 0;

  &:focus {
    padding: 2px 8px 1px 4px;
    background-position: 2px 2px;
    box-shadow: inset -1px -1px 0px #ffffff, inset 1px 1px 0px #0c0c0c,
      inset -2px -2px 0px #bbc3c4, inset 2px 2px 0px #808088;
  }
`;
const NavListCloseButton = styled.button`
  ${SharedNavListButtonStyles}
  background-image: url(data:image/gif;base64,R0lGODlhDQALAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAANAAsAAAIUlI+pKwDoVGxvucmwvblqo33MqBQAOw==);
`;
const NavListMinimizeButton = styled.button`
  ${SharedNavListButtonStyles}
  background-image: url(data:image/gif;base64,R0lGODlhDQALAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAANAAsAAAIOlI+py+0PozSg2mXvFAUAOw==);
`;
const NavListMaximizeButton = styled.button`
  ${SharedNavListButtonStyles}
  background-image: url(data:image/gif;base64,R0lGODlhDQALAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAANAAsAAAIXlI8Jy4wNXzJAznqwsjtPoYFfCDXfWQAAOw==);
`;
const NavListRestoreButton = styled.button`
  ${SharedNavListButtonStyles}
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='8' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000' d='M2 0h6v2H2zM7 2h1v4H7zM2 2h1v1H2zM6 5h1v1H6zM0 3h6v2H0zM5 5h1v4H5zM0 5h1v4H0zM1 8h4v1H1z'/%3E%3C/svg%3E");
  background-position: top 2px left 3px;
`;
const ResetButton = styled.button`
  width: 100%;
  font: inherit;
  padding: 0;
  border: 0;
  margin: 0;
  background: transparent;
  color: inherit;
  touch-action: none;
`;

export default Window;
