import { useEffect, useState } from 'react';

export default function useSimpleClickOutside(
  refs,
  onWindowClick,
  onOutsideClick
) {
  const handleClickOutside = (event) => {
    const matchingWindow = refs.current.find(
      ({ element }) =>
        element &&
        (element.contains(event.target) ||
          event.target.classList.contains('ContextMenuButton'))
    );

    if (matchingWindow) {
      onWindowClick(event);
      // only handle outside clicks when we're not clicking a taskbar button
    } else if (!event.target.classList.contains('ButtonProgram')) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside, false);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside, false);
    };
  });
}
