import React from "react";
import styled, { css } from "styled-components";
import ListSetupFolder from "../images/list_setup_folder.png";
import ListSetupFolderClosed from "../images/list_setup_folder_closed.png";
import { Buddies } from "./BuddyList";

export const ListSetup = ({
  buddies,
  setBuddies,
}: {
  buddies: Buddies;
  setBuddies: React.Dispatch<React.SetStateAction<Buddies>>;
}) => {
  const onDblClickGroup = (group: string) => {
    // expand just the group that was clicked
    setBuddies((prevBuddies: Buddies) => {
      const newBuddies = { ...prevBuddies };
      const updatedBuddy = {
        ...newBuddies[group],
        expanded: !newBuddies[group].expanded,
      };
      return { ...newBuddies, [group]: updatedBuddy };
    });
  };

  return (
    <MainList>
      {Object.entries(buddies).map(([group, { list, expanded }]) => (
        <MainListItem tabIndex={0}>
          <GroupButton
            onClick={() => {
              if (false) {
                onDblClickGroup(group);
              }
            }}
            onDoubleClick={() => onDblClickGroup(group)}
          >
            {expanded ? (
              <Icon src={ListSetupFolder} alt="" width={16} height={12} />
            ) : (
              <Icon src={ListSetupFolderClosed} alt="" width={16} height={12} />
            )}
            <GroupText>{group}</GroupText>
          </GroupButton>
          <BuddyNameList>
            {expanded &&
              list.map((buddy) => (
                <li>
                  <BuddyButton>{buddy}</BuddyButton>
                </li>
              ))}
          </BuddyNameList>
        </MainListItem>
      ))}
    </MainList>
  );
};

const focusStyles = css`
  background-color: yellow;
  outline: 1px dotted #010101;
`;
const MainList = styled.ul`
  padding: 4px 0;
  margin: 0;
  list-style: none;
`;
const MainListItem = styled.li`
  padding: 0 4px;
`;
const Icon = styled.img``;
const GroupText = styled.p`
  margin: 0;
  font-weight: bold;
`;
const GroupButton = styled.button`
  display: flex;
  gap: 4px;
  font: inherit;
  appearance: none;
  padding: 2px;

  &:focus {
    ${focusStyles}
  }
`;
const BuddyButton = styled.button`
  font: inherit;
  appearance: none;
  padding: 2px;
  padding-left: 14px;
  position: relative;

  &:focus {
    ${focusStyles}
    z-index: 1;
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -6px;
    width: 6px;
    height: 1px;
    border-bottom: 1px dotted black;
  }
`;
const BuddyNameList = styled.ul`
  list-style: none;
  padding: 0;
  padding-left: 16px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 8px;
    height: calc(100% - 7px);
    overflow: hidden;
    width: 5px;
    border-left: 1px dotted black;
  }
`;
