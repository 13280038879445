import React, { useRef } from "react";
import Window from "./Window";
import styled from "styled-components";

const SignUpWindow = ({ onClose }: { onClose: any }) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  return (
    <Window
      title="Stay in the loop!"
      navActionList={["close"]}
      onClose={onClose}
    >
      <Content>
        <Subtitle>Get monthly updates on our progress (and no more!)</Subtitle>
        <Form
          name="Newsletter"
          action="/subscribe"
          method="post"
          target="_blank"
          onSubmit={() => {
            const ref = emailInputRef?.current;
            if (ref) {
              setTimeout(() => {
                ref.value = "";
              }, 500);
            }
          }}
        >
          <input type="hidden" name="form-name" value="Newsletter" />
          <Input
            ref={emailInputRef}
            type="email"
            placeholder="Email"
            name="email"
            required
          />
          <ActionsWrap>
            <ActionButton type="submit">Submit</ActionButton>
            <ActionButton type="button" onClick={onClose}>
              Cancel
            </ActionButton>
          </ActionsWrap>
        </Form>
      </Content>
    </Window>
  );
};

const Content = styled.div`
  padding: 5px 10px 12px;
`;
const Form = styled.form`
  display: block;
`;
const Title = styled.h3`
  margin: 5px 0;
`;
const Subtitle = styled.p`
  margin: 4px 0 10px;
`;
const Input = styled.input`
  width: 100%;
  position: relative;
  padding: 4px;
  font-size: 11px;
  border: none;
  box-shadow: inset -1px -1px 0px #ffffff, inset 1px 1px 0px 0px #808088,
    inset -2px -2px 0px #bbc3c4, inset 2px 2px 0px 0px #0c0c0c;
  width: 100%;
  font-family: inherit;
  cursor: url(static/media/Text-min.a4660c67.png), text !important;
  outline: 0;
  margin: 0;
  border-radius: 0;

  &::placeholder {
    color: gray;
  }
`;

const ActionsWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 8px 0;
`;
const ActionButton = styled.button`
  margin: 0px 10px;
  min-width: 48px;
  outline-width: 1px;
  outline-offset: -5px;
  padding: 5px 16px;
  box-shadow: inset -1px -1px 0px #0c0c0c, inset 1px 1px 0px #ffffff,
    inset -2px -2px 0px #808088, inset 2px 2px 0px #bbc3c4;
  border: 0px solid transparent;
  background-color: #bbc3c4;
  position: relative;
  user-select: none;
  font: inherit;
  appearance: none;

  &:focus {
    outline: 1px dotted #000;
    outline-offset: -4px;
  }
`;

export default SignUpWindow;
