import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Window from "./Window";
import Divider from "./Divider";
import AIMMainImage from "../images/aim_main.png";
import AIMHelpIcon from "../images/aim_help.png";
import AIMHelpHoverIcon from "../images/aim_help_hover.png";
import AIMSetupIcon from "../images/aim_setup.png";
import AIMSetupHoverIcon from "../images/aim_setup_hover.png";
import ScreenNameImage from "../images/screenname.png";
import AIMSignOnActiveIcon from "../images/aim_signon_active.png";
import AIMSignOnActiveHoverIcon from "../images/aim_signon_active_hover.png";
import AIMSignOnDisabledIcon from "../images/aim_signon_disabled.png";
import HoverImgButton from "./HoverImgButton";
import { AIMContext } from "../AIMProvider";
import AIMHeaderIconOg from "../images/aim_header_icon.png";
import AIMHeaderIcon from "../images/aim_header_icon_new.png";

const wait = (signal: AbortSignal, ms: number) => {
  if (signal.aborted) {
    return Promise.reject(new DOMException("Aborted", "AbortError"));
  }

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      resolve(true);
    }, ms);

    // Listen for abort event on signal
    signal.addEventListener("abort", () => {
      clearTimeout(timeout);
      reject(new DOMException("Aborted", "AbortError"));
    });
  });
};

const getPasswordAsterisks = (password: string) => {
  return password
    .split("")
    .map((x) => "*")
    .join("");
};

const getLoginStepText = (getLoginStepText: string) => {
  if (getLoginStepText === "1") {
    return "1. Connecting...";
  } else if (getLoginStepText === "2") {
    return "2. Verifying name and password...";
  } else {
    return "3. Starting services...";
  }
};

let controller: AbortController;

interface Props {
  width?: number;
  height?: number;
  icon?: string;
  title?: string;
}

const Login = ({ width, height, icon, title }: Props) => {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const [password, setPassword] = useState("");
  // const [signOnButtonState, setSignOnButtonState] = useState(null);
  const { loginStatus, setLoginStatus, username, setUsername, debugMode } =
    useContext(AIMContext);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    // Creation of an AbortController signal
    controller = new AbortController();
    const signal = controller.signal;

    try {
      setLoginStatus("1");
      await wait(signal, 500);
      setLoginStatus("2");
      await wait(signal, 2000);
      setLoginStatus("3");
      await wait(signal, 3000);
      setLoginStatus("done");
    } catch (e) {
      console.error("Login cancelled");
    }
  };

  useEffect(() => {
    if (Number(loginStatus)) {
      cancelButtonRef.current?.focus();
    }
  }, [loginStatus]);

  return (
    <>
      <Window
        width={204}
        title="Sign On"
        icon={debugMode ? AIMHeaderIconOg : AIMHeaderIcon}
      >
        <ContentWrap>
          {debugMode ? (
            <img
              src={AIMMainImage}
              alt="AOL Instant Messenger"
              width="180"
              height="143"
            />
          ) : (
            <div
              style={{
                width: "180px",
                height: "143px",
                display: "block",
                margin: "auto",
                background: "#0860a8",
              }}
            />
          )}

          <DividerWrap>
            <Divider />
          </DividerWrap>
          {Number(loginStatus) ? (
            <LoginStepWrap>
              <p>{username}</p>
              <p>{getLoginStepText(loginStatus)}</p>
              <CancelButton
                ref={cancelButtonRef}
                onClick={() => {
                  setLoginStatus("");
                  controller.abort();
                }}
              >
                Cancel
              </CancelButton>
            </LoginStepWrap>
          ) : (
            <>
              <Form onSubmit={handleSubmit} autoComplete="off">
                <FormInputWrap>
                  <FormWrap>
                    <FormLabel htmlFor="screenName">
                      <img
                        src={ScreenNameImage}
                        alt="Screen Name"
                        width="101"
                        height="12"
                      />
                    </FormLabel>
                    <SelectWrap>
                      <FormInput
                        id="screenName"
                        placeholder={`<New User>`}
                        value={username}
                        autoComplete="off"
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <SelectArrow type="button"></SelectArrow>
                    </SelectWrap>
                    <Link href="/">Get a Screen Name</Link>
                  </FormWrap>
                  <FormWrap>
                    <FormLabel htmlFor="password">
                      <strong>Password</strong>
                    </FormLabel>
                    <FormInput
                      id="password"
                      type="text"
                      value={getPasswordAsterisks(password)}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled
                      placeholder="No need for a password yet!"
                    />
                    <Link href="/">Forgot Password?</Link>
                  </FormWrap>
                  <CheckboxWrap>
                    <Flex>
                      <Checkbox id="savePassword" type="checkbox" />
                      <label htmlFor="savePassword">Save password</label>
                    </Flex>
                    <Flex>
                      <Checkbox id="autoLogin" type="checkbox" />
                      <label htmlFor="autoLogin">Auto-login</label>
                    </Flex>
                  </CheckboxWrap>
                </FormInputWrap>
                <ButtonWrap>
                  <HoverImgButton
                    img={AIMHelpIcon}
                    hoverImg={AIMHelpHoverIcon}
                    imgProps={{
                      width: 19,
                      height: 32,
                    }}
                    buttonProps={{
                      style: { marginLeft: "10px", marginRight: "18px" },
                    }}
                  />
                  <HoverImgButton
                    img={AIMSetupIcon}
                    hoverImg={AIMSetupHoverIcon}
                    imgProps={{
                      width: 25,
                      height: 31,
                    }}
                  />
                  <HoverImgButton
                    img={AIMSignOnActiveIcon}
                    hoverImg={AIMSignOnActiveHoverIcon}
                    disabledImg={AIMSignOnDisabledIcon}
                    imgProps={{
                      width: 38,
                      height: 32,
                    }}
                    buttonProps={{
                      type: "submit",
                      style: {
                        marginLeft: "auto",
                        marginRight: "7px",
                      },
                      disabled: !username,
                    }}
                  />
                </ButtonWrap>
              </Form>
              <footer>
                <FooterText>Version: 5.9.3857</FooterText>
              </footer>
            </>
          )}
        </ContentWrap>
      </Window>
    </>
  );
};

const DividerWrap = styled.div`
  margin: 6px 0;
`;
const ContentWrap = styled.div`
  padding: 3px 6px 6px;
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`;
const CheckboxWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  margin-top: 10px;
`;
const SelectWrap = styled.div`
  position: relative;
`;
const SelectArrow = styled.button`
  padding: 0;
  position: absolute;
  box-shadow: inset -1px -1px 0px #0c0c0c, inset 1px 1px 0px #bbc3c4,
    inset -2px -2px 0px #808088, inset 2px 2px 0px #ffffff;
  height: 16px;
  width: 16px;
  left: calc(100% - 18px);
  top: 2px;
  background-color: #bbc3c4;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(data:image/gif;base64,R0lGODlhBwAEAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAAHAAQAAAIIhA+CKWoNmSgAOw==);
`;
const Link = styled.a`
  color: #3838ed;
  display: block;
  margin-top: 3px;
`;
const Form = styled.form``;
const FormWrap = styled.div`
  & + & {
    margin-top: 8px;
  }
`;
const FormInputWrap = styled.div`
  margin: 0 10px;
`;
const FormLabel = styled.label`
  display: block;
  margin-right: 6px;
`;
const FormInput = styled.input`
  position: relative;
  padding: 4px;
  font-size: 11px;
  border: none;
  box-shadow: inset -1px -1px 0px #ffffff, inset 1px 1px 0px 0px #808088,
    inset -2px -2px 0px #bbc3c4, inset 2px 2px 0px 0px #0c0c0c;
  width: 100%;
  font-family: inherit;
  cursor: url(static/media/Text-min.a4660c67.png), text !important;
  outline: 0;
  margin: 0;
  border-radius: 0;

  &:disabled {
    background-color: #bbc3c4;
  }
`;
const LoginStepWrap = styled.div`
  text-align: center;
  padding: 0 0 8px;

  p {
    margin: 8px 0;
  }
`;
const CancelButton = styled.button`
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  min-height: 23px;
  min-width: 60px;
  padding: 0 12px;
  font-family: "Pixelated MS Sans Serif", Arial;
  font-size: 11px;
  background: silver;
  box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey,
    inset 2px 2px #dfdfdf;

  &:focus {
    outline: 1px dotted #000000;
    outline-offset: -4px;
  }
`;
const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const Checkbox = styled.input`
  opacity: 0;
  display: none;
  cursor: pointer;

  & + label {
    position: relative;
    padding: 1px 0px;
    padding-left: 16px;

    &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 1px;
      width: 20px;
      height: 12px;
      background-repeat: no-repeat;
      width: 13px;
      height: 13px;
      background-color: #ffffff;
      box-shadow: inset -1px -1px 0px #ffffff, inset 1px 1px 0px 0px #808088,
        inset -2px -2px 0px #bbc3c4, inset 2px 2px 0px 0px #0c0c0c;
    }
  }

  &:checked + label {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  &:checked + label::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 1px;
    width: 20px;
    height: 12px;
    background-repeat: no-repeat;
    width: 13px;
    height: 13px;
    background-color: #ffffff;
    box-shadow: inset -1px -1px 0px #ffffff, inset 1px 1px 0px 0px #808088,
      inset -2px -2px 0px #bbc3c4, inset 2px 2px 0px 0px #0c0c0c;
    background-image: url(data:image/gif;base64,R0lGODlhBwAHAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAAHAAcAAAIMlA9nwMj9xGuLIlUAADs=);
    background-position: center;
    background-size: 8px;
  }
  &:disabled + label {
    color: #808088;
  }
  &:disabled + label::before {
    background-color: #bbc3c4;
  }
`;
const FooterText = styled.p`
  text-align: center;
  margin: 0 0 2px;
  font-size: 9px;
`;

export default Login;
