import React, { useRef, useState } from "react";
import styled from "styled-components";
import useGenericClickOutside from "../useGenericClickOutside";
import SmileyIcon from "../images/smiley.png";

import SmileSmall from "../images/smileys/smile_s.png";
import SmileMedium from "../images/smileys/smile_m.png";
import SmileLarge from "../images/smileys/smile_l.png";
import SmileXLarge from "../images/smileys/smile_xl.png";

import FrownSmall from "../images/smileys/frown_s.png";
import FrownMedium from "../images/smileys/frown_m.png";
import FrownLarge from "../images/smileys/frown_l.png";
import FrownXLarge from "../images/smileys/frown_xl.png";

import WinkSmall from "../images/smileys/wink_s.png";
import WinkMedium from "../images/smileys/wink_m.png";
import WinkLarge from "../images/smileys/wink_l.png";
import WinkXLarge from "../images/smileys/wink_xl.png";

import TongueSmall from "../images/smileys/tongue_s.png";
import TongueMedium from "../images/smileys/tongue_m.png";
import TongueLarge from "../images/smileys/tongue_l.png";
import TongueXLarge from "../images/smileys/tongue_xl.png";

import OfaceSmall from "../images/smileys/oface_s.png";
import OfaceMedium from "../images/smileys/oface_m.png";
import OfaceLarge from "../images/smileys/oface_l.png";
import OfaceXLarge from "../images/smileys/oface_xl.png";

import KissSmall from "../images/smileys/kiss_s.png";
import KissMedium from "../images/smileys/kiss_m.png";
import KissLarge from "../images/smileys/kiss_l.png";
import KissXLarge from "../images/smileys/kiss_xl.png";

import AngrySmall from "../images/smileys/angry_s.png";
import AngryMedium from "../images/smileys/angry_m.png";
import AngryLarge from "../images/smileys/angry_l.png";
import AngryXLarge from "../images/smileys/angry_xl.png";

import GlassesSmall from "../images/smileys/glasses_s.png";
import GlassesMedium from "../images/smileys/glasses_m.png";
import GlassesLarge from "../images/smileys/glasses_l.png";
import GlassesXLarge from "../images/smileys/glasses_xl.png";

import MoneySmall from "../images/smileys/money_s.png";
import MoneyMedium from "../images/smileys/money_m.png";
import MoneyLarge from "../images/smileys/money_l.png";
import MoneyXLarge from "../images/smileys/money_xl.png";

import FootSmall from "../images/smileys/foot_s.png";
import FootMedium from "../images/smileys/foot_m.png";
import FootLarge from "../images/smileys/foot_l.png";
import FootXLarge from "../images/smileys/foot_xl.png";

import EmbarassedSmall from "../images/smileys/embarassed_s.png";
import EmbarassedMedium from "../images/smileys/embarassed_m.png";
import EmbarassedLarge from "../images/smileys/embarassed_l.png";
import EmbarassedXLarge from "../images/smileys/embarassed_xl.png";

import HaloSmall from "../images/smileys/halo_s.png";
import HaloMedium from "../images/smileys/halo_m.png";
import HaloLarge from "../images/smileys/halo_l.png";
import HaloXLarge from "../images/smileys/halo_xl.png";

import SlantSmall from "../images/smileys/slant_s.png";
import SlantMedium from "../images/smileys/slant_m.png";
import SlantLarge from "../images/smileys/slant_l.png";
import SlantXLarge from "../images/smileys/slant_xl.png";

import CrySmall from "../images/smileys/cry_s.png";
import CryMedium from "../images/smileys/cry_m.png";
import CryLarge from "../images/smileys/cry_l.png";
import CryXLarge from "../images/smileys/cry_xl.png";

import SilentSmall from "../images/smileys/silent_s.png";
import SilentMedium from "../images/smileys/silent_m.png";
import SilentLarge from "../images/smileys/silent_l.png";
import SilentXLarge from "../images/smileys/silent_xl.png";

import GrinSmall from "../images/smileys/grin_s.png";
import GrinMedium from "../images/smileys/grin_m.png";
import GrinLarge from "../images/smileys/grin_l.png";
import GrinXLarge from "../images/smileys/grin_xl.png";

import IconBarIcon from "./IconBarIcon";

import "./smilies.scss";
import { InlineStyles } from "../types";
import { Editor } from "@tiptap/core";

const smileyImages = {
  smile: SmileMedium,
  frown: FrownMedium,
  wink: WinkMedium,
  tongue: TongueMedium,
  oface: OfaceMedium,
  kiss: KissMedium,
  angry: AngryMedium,
  glasses: GlassesMedium,
  money: MoneyMedium,
  foot: FootMedium,
  embarassed: EmbarassedMedium,
  halo: HaloMedium,
  slant: SlantMedium,
  cry: CryMedium,
  silent: SilentMedium,
  grin: GrinMedium,
};

export const smileyNames = Object.keys(smileyImages);

const Popup = ({
  setIsOpen,
  editor,
  inlineStyles,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editor: Editor;
  inlineStyles: InlineStyles;
}) => {
  const popupRef = useRef<HTMLDivElement>(null);
  useGenericClickOutside(popupRef, () => {
    setIsOpen(false);
  });

  const { color, bgColor, fontFamily, fontSize } = inlineStyles;

  const handleClick = (name: string) => {
    editor.commands.insertContent(
      ` <span style="font-size: ${fontSize};" class="smiley smiley--${name}">&nbsp;</span> `
    );
    // @ts-ignore
    editor.chain().focus().setColor(color, bgColor, fontFamily, fontSize).run();
    setIsOpen(false);
  };

  return (
    <SmileyPopup ref={popupRef}>
      {Object.entries(smileyImages).map(([name, url]) => (
        <SmileyButton key={name} onClick={() => handleClick(name)}>
          <img src={url} alt="" />
        </SmileyButton>
      ))}
    </SmileyPopup>
  );
};

function Smilies({
  editor,
  inlineStyles,
}: {
  editor: any;
  inlineStyles: InlineStyles;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <IconContainer>
      <IconBarIcon
        icon={SmileyIcon}
        width={11}
        height={11}
        onClick={handleClick}
      />
      {isOpen && (
        <Popup
          setIsOpen={setIsOpen}
          editor={editor}
          inlineStyles={inlineStyles}
        />
      )}
    </IconContainer>
  );
}

const IconContainer = styled.span`
  position: relative;
`;

const SmileyPopup = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
  background: gray;
  padding: 4px;

  width: 138px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  background-color: #c0c0c0;
  box-shadow: inset -1px -1px 0px #0c0c0c, inset 1px 1px 0px #bbc3c4,
    inset -2px -2px 0px #808088, inset 2px 2px 0px #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
`;

const SmileyButton = styled.button`
  padding: 2px;
  &:hover,
  &:focus {
    background-color: #0078d7;
  }
`;

export default Smilies;
