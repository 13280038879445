import React, { useContext } from "react";
import styled from "styled-components";
import Window from "./Window";
import Divider from "./Divider";
import ChatWindowIcon from "../images/chatwindow.png";
import MyEditor from "./MyEditor";
import { AIMContext } from "../AIMProvider";

interface Props {
  width: number;
  height?: number;
  icon?: React.ReactNode;
  title: string;
  onClose: Function;
}

const ChatWindow = ({ width, height, icon, title, onClose }: Props) => {
  const { buddyIconSrc } = useContext(AIMContext);
  return (
    <>
      <Window
        title={title}
        width={width}
        icon={ChatWindowIcon}
        topNavOptions={[
          {
            name: "File",
          },
          {
            name: "Edit",
          },
          {
            name: "View",
          },
          {
            name: "People",
          },
        ]}
        onClose={onClose}
      >
        <Divider />
        <OuterWrapper>
          {true && (
            <BuddyIconSection>
              <img
                src="images/buddy_icons/badassbuddy_com-robot.gif"
                width="48"
                height="48"
                alt=""
              />
              {buddyIconSrc && (
                <img src={buddyIconSrc} width="48" height="48" alt="" />
              )}
            </BuddyIconSection>
          )}
          <Wrapper>
            <MyEditor title={title} />
          </Wrapper>
        </OuterWrapper>
      </Window>
    </>
  );
};

const BuddyIconSection = styled.div`
  padding-top: 7px;
  padding-right: 10px;
  display: grid;
  grid-template-rows: 137px 48px;

  img {
    display: block;
  }
`;
const OuterWrapper = styled.div`
  display: flex;
  padding: 0 10px;
`;
const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export default ChatWindow;
