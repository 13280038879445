import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

interface Props {
  onClick?: React.MouseEventHandler;
  img: string;
  buttonProps?: React.HTMLProps<HTMLButtonElement> | any;
  imgProps?: React.HTMLAttributes<HTMLImageElement> | any;
  hoverImgProps?: React.HTMLProps<HTMLImageElement> &
    React.HTMLProps<HTMLImageElement>;
  hoverImg: string;
  disabledImg?: string;
  alt?: string;
}

const HoverImgButton = ({
  onClick,
  img,
  buttonProps,
  imgProps,
  hoverImgProps,
  hoverImg,
  disabledImg,
  alt,
}: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <Button
      onPointerOver={() => {
        setIsHovering(true);
      }}
      onFocus={() => {
        setIsHovering(true);
      }}
      onPointerLeave={() => {
        setIsHovering(false);
      }}
      onBlur={() => {
        setIsHovering(false);
      }}
      onClick={onClick}
      type={buttonProps?.type ? buttonProps.type : "button"}
      {...buttonProps}
    >
      <div
        style={{
          minWidth: Math.max(
            Number(hoverImgProps?.width) || 0,
            imgProps?.width || 0
          ),
        }}
      >
        <Img
          src={buttonProps.disabled ? disabledImg : isHovering ? hoverImg : img}
          alt={alt}
          {...(hoverImgProps && isHovering ? hoverImgProps : imgProps)}
        />
      </div>
    </Button>
  );
};

export default HoverImgButton;

HoverImgButton.propTypes = {
  img: PropTypes.string.isRequired,
  hoverImg: PropTypes.string.isRequired,
  buttonProps: PropTypes.object,
  imgProps: PropTypes.object,
};
HoverImgButton.defaultProps = {
  buttonProps: {},
  imgProps: {},
};

const Button = styled.button`
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;

  &::before {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
  }
`;
const Img = styled.img`
  display: block;
`;
