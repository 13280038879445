import { useState, useEffect } from 'react';

function useDrag() {
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingEnabled, setIsDraggingEnabled] = useState(true);
  const [previousCoords, setPreviousCoords] = useState({ x: 0, y: 0 });
  const [initialCoords, setInitialCoords] = useState({ x: 0, y: 0 });
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  // Adds the "pointermove" event to the window since we want to be able to drag the element
  // all over the screen while not waiting for the drafRef to catch up to the pointer.
  useEffect(() => {
    function handleDrag(e) {
      setCoords({
        x: previousCoords.x + (e.clientX - initialCoords.x),
        y: previousCoords.y + (e.clientY - initialCoords.y),
      });
    }

    if (isDragging) {
      window.addEventListener('pointermove', handleDrag);
    }

    return () => {
      window.removeEventListener('pointermove', handleDrag);
    };
  }, [initialCoords, previousCoords, isDragging]);

  function handlePointerDown(e) {
    if (!isDraggingEnabled) {
      return;
    }

    setInitialCoords({ x: e.clientX, y: e.clientY });

    setIsDragging(true);
  }

  function handleDragStop(e) {
    setPreviousCoords(coords);
    setIsDragging(false);
  }

  function transformStyles() {
    return { transform: `translate(${coords.x}px, ${coords.y}px)` };
  }

  return {
    dragEventHandlers: {
      onPointerDown: handlePointerDown,
      onPointerUp: handleDragStop,
    },
    isDragging,
    setIsDraggingEnabled,
    transformStyles,
  };
}

export default useDrag;
