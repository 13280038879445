import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import Window from "./Window";
import Divider from "./Divider";
import HoverImgButton from "./HoverImgButton";
import AwayMessageCreator from "./AwayMessageCreator";
import { AIMContext } from "../AIMProvider";
import BuddyListImage from "../images/buddylist.png";
import NewIMImage from "../images/new_im.png";
import NewIMHoverImage from "../images/new_im_hover.png";
import NewGroupIMImage from "../images/new_group_im.png";
import NewGroupIMHoverImage from "../images/new_group_im_hover.png";
import GetInfoImage from "../images/get_info.png";
import GetInfoHoverImage from "../images/get_info_hover.png";
import AIMHeaderIconOg from "../images/aim_header_icon.png";
import AIMHeaderIcon from "../images/aim_header_icon_new.png";
import AddBuddyNoText from "../images/add_buddy_notext.png";
import AddBuddyNoTextHover from "../images/add_buddy_notext_hover.png";
import AddGroupIcon from "../images/add_group.png";
import AddGroupIconHover from "../images/add_group_hover.png";
import RemoveBuddy from "../images/remove_buddy.png";
import RemoveBuddyHover from "../images/remove_buddy_hover.png";
import useContextMenu from "../hooks/useContextMenu";
import { ListSetup } from "./ListSetup";
import PreferencesMenu from "./PreferencesMenu";

interface Props {
  width?: number;
  height?: number;
  icon?: React.ReactNode;
  title?: string;
}

export interface Buddies {
  [key: string]: {
    list: string[];
    expanded: boolean;
  };
}

const BuddyList = ({ width, height, icon, title }: Props) => {
  const [activeTab, setActiveTab] = useState("online");
  const [openMyAim, setOpenMyAim] = useState(false);
  const [openAwayMessage, setOpenAwayMessage] = useState(false);
  const [openPreferencesMenu, setOpenPreferencesMenu] = useState(false);
  const { username, setOpenChatWindows, debugMode } = useContext(AIMContext);

  const isTouchScreen = window?.matchMedia?.("(pointer: coarse)")?.matches;

  const [buddies, setBuddies] = useState<Buddies>({
    Buddies: {
      list: ["SmarterChild", "AnotherChild"],
      expanded: true,
    },
    Family: {
      list: [],
      expanded: true,
    },
    "Co-workers": {
      list: [],
      expanded: true,
    },
    Offline: {
      list: [],
      expanded: true,
    },
  });

  const handleMyAimMenu = () => {
    // white space change
    setOpenMyAim(true);
  };

  const onDblClickGroup = (group: string) => {
    // expand just the group that was clicked
    setBuddies((prevBuddies: Buddies) => {
      const newBuddies = { ...prevBuddies };
      const updatedBuddy = {
        ...newBuddies[group],
        expanded: !newBuddies[group].expanded,
      };
      return { ...newBuddies, [group]: updatedBuddy };
    });
  };

  const openAChatWindow = (buddyName: string) => {
    setOpenChatWindows((currentOpenChatWindows: string[]) => {
      if (!currentOpenChatWindows.includes(buddyName)) {
        return [...currentOpenChatWindows, buddyName];
      }

      return currentOpenChatWindows;
    });
  };

  const handleEditOptionsMenu = () => {};

  const handleEditPreferencesMenu = () => {
    setOpenPreferencesMenu(true);
  };

  const MyAimOptions = [
    [
      {
        name: "Away Message",
        onClick: handleMyAimMenu,
        items: [
          [
            {
              name: "New Message...",
              onClick: () => {
                setOpenAwayMessage(true);
              },
            },
          ],
        ],
      },
      {
        name: "Edit Options",
        onClick: handleEditOptionsMenu,
        items: [
          [
            {
              name: "Edit Preferences",
              onClick: handleEditPreferencesMenu,
            },
          ],
        ],
      },
    ],
  ];

  const contextMenuConfig = [
    [
      {
        name: "Get Member Info...",
      },
      {
        name: "Send Instant Message...",
        onClick: () => openAChatWindow("SmarterChild"),
      },
    ],
  ];

  const { handleContextMenu, ContextMenuPortal } = useContextMenu();

  const handleBuddyContextMenu = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    buddyName: string
  ) => {
    const contextMenuConfig = [
      [
        {
          name: "Get Member Info...",
        },
        {
          name: "Send Instant Message...",
          onClick: () => openAChatWindow(buddyName),
        },
      ],
    ];

    handleContextMenu(e, contextMenuConfig);
  };

  return (
    <>
      <Window
        title={`${username}'s Buddy List`}
        width={220}
        icon={debugMode ? AIMHeaderIconOg : AIMHeaderIcon}
        topNavOptions={[
          {
            name: "MyAIM",
            items: MyAimOptions,
            onClick: handleMyAimMenu,
            setShowContextMenu: setOpenMyAim,
            showMenu: openMyAim,
          },
          { name: "People" },
          { name: "Help" },
        ]}
      >
        <Divider />
        {debugMode ? (
          <Image
            src={BuddyListImage}
            alt="AOL Instant Messenger"
            width={120}
            height={60}
          />
        ) : (
          <Image />
        )}

        <TabArea>
          <TabList>
            <TabListItem>
              <TabButton
                className={activeTab === "online" ? "is-active" : ""}
                onClick={() => setActiveTab("online")}
              >
                Online
              </TabButton>
            </TabListItem>
            <TabListItem>
              <TabButton
                className={activeTab === "list setup" ? "is-active" : ""}
                onClick={() => setActiveTab("list setup")}
              >
                List Setup
              </TabButton>
            </TabListItem>
          </TabList>
          <TabContentWrap>
            <TabContent>
              {activeTab === "online" ? (
                <BuddyGroups>
                  <BuddyGroup>
                    {Object.keys(buddies).map((group, index) => (
                      <React.Fragment key={group}>
                        <li>
                          <BuddyGroupItem
                            isExpanded={buddies[group].expanded}
                            onDoubleClick={() => {
                              if (!isTouchScreen) {
                                onDblClickGroup(group);
                              }
                            }}
                            onClick={() => {
                              if (isTouchScreen) {
                                onDblClickGroup(group);
                              }
                            }}
                            autoFocus={index === 0}
                          >
                            <BuddyText>
                              {group} (0/{buddies[group].list.length})
                            </BuddyText>
                          </BuddyGroupItem>
                        </li>
                        {buddies[group].expanded &&
                          buddies[group].list.map((buddyName) => (
                            <li key={buddyName}>
                              <BuddyItem
                                key={buddyName}
                                onDoubleClick={() => {
                                  if (!isTouchScreen) {
                                    openAChatWindow(buddyName);
                                  }
                                }}
                                onClick={() => {
                                  if (isTouchScreen) {
                                    openAChatWindow(buddyName);
                                  }
                                }}
                                onContextMenu={(e) =>
                                  handleBuddyContextMenu(e, buddyName)
                                }
                              >
                                {buddyName}
                              </BuddyItem>
                            </li>
                          ))}
                      </React.Fragment>
                    ))}
                  </BuddyGroup>
                </BuddyGroups>
              ) : (
                <ListSetup buddies={buddies} setBuddies={setBuddies} />
              )}
            </TabContent>
            {/* New DM and other icons go here */}
            <ButtonWrap>
              {activeTab === "online" ? (
                <>
                  <HoverImgButton
                    img={NewIMImage}
                    hoverImg={NewIMHoverImage}
                    imgProps={{
                      width: 19,
                      height: 20,
                    }}
                    buttonProps={{
                      style: { marginLeft: "4px", marginRight: "4px" },
                    }}
                    alt="New Instant Message"
                  />
                  <HoverImgButton
                    img={NewGroupIMImage}
                    hoverImg={NewGroupIMHoverImage}
                    imgProps={{
                      width: 22,
                      height: 22,
                    }}
                    buttonProps={{
                      style: { marginLeft: "4px", marginRight: "4px" },
                    }}
                    alt="New Instant Message"
                  />
                  <HoverImgButton
                    img={GetInfoImage}
                    hoverImg={GetInfoHoverImage}
                    imgProps={{
                      width: 22,
                      height: 16,
                    }}
                    buttonProps={{
                      style: { marginLeft: "6px", marginRight: "4px" },
                    }}
                    alt="New Instant Message"
                  />
                </>
              ) : (
                <>
                  <HoverImgButton
                    img={AddBuddyNoText}
                    hoverImg={AddBuddyNoTextHover}
                    imgProps={{
                      width: 17,
                      height: 23,
                    }}
                    buttonProps={{
                      style: { marginLeft: "4px", marginRight: "4px" },
                    }}
                    alt="New Instant Message"
                  />
                  <HoverImgButton
                    img={AddGroupIcon}
                    hoverImg={AddGroupIconHover}
                    imgProps={{
                      width: 24,
                      height: 23,
                    }}
                    buttonProps={{
                      style: { marginLeft: "4px", marginRight: "4px" },
                    }}
                    alt="New Instant Message"
                  />
                  <HoverImgButton
                    img={RemoveBuddy}
                    hoverImg={RemoveBuddyHover}
                    imgProps={{
                      width: 15,
                      height: 21,
                    }}
                    buttonProps={{
                      style: {
                        marginLeft: "4px",
                        marginRight: "4px",
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                      },
                    }}
                    alt="New Instant Message"
                  />
                </>
              )}
            </ButtonWrap>
          </TabContentWrap>
        </TabArea>
      </Window>
      {openAwayMessage &&
        ReactDOM.createPortal(
          <AwayMessageCreator setOpenAwayMessage={setOpenAwayMessage} />,
          document.getElementById("modals") as HTMLElement
        )}
      {openPreferencesMenu &&
        ReactDOM.createPortal(
          <PreferencesMenu setOpen={setOpenPreferencesMenu} />,
          document.getElementById("modals") as HTMLElement
        )}
      <ContextMenuPortal />
    </>
  );
};

const focusStyles = css`
  background-color: yellow;
  outline: 1px dotted #010101;
`;
const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 4px;
  height: 31px;
`;
const TabArea = styled.div`
  margin: 0 3px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const TabContent = styled.div`
  position: relative;
  min-height: 300px;
  height: 100%;
  background-color: #fff;
  margin: 3px;
  box-shadow: inset 2px 2px 1px #000;
`;
const TabContentWrap = styled.div`
  border: 1px solid #f9f5f2;
  position: relative;
  background-color: #c0c0c0;
  padding-top: 4px;
  box-shadow: 1px 1px 1px #030300;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const TabButton = styled.button`
  position: relative;
  appearance: none;
  padding: 4px 8px;
  border: 0;
  margin: 0;
  background: transparent;
  font: inherit;
  border-radius: 4px 0 0 0;
  border: 1px solid #f9f5f2;
  border-bottom: 0;
  box-shadow: 1px 1px 1px #030300;

  &.is-active {
    border-bottom-color: transparent;
    font-weight: bold;
    border-right: 0;
    z-index: 1;
    position: relative;
    background: #c0c0c0;
    padding: 5px 8px;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: calc(100% - 2px);
      width: 100%;
      height: 5px;
      background: #c0c0c0;
      z-index: 1;
    }
  }
`;
const TabList = styled.ul`
  display: flex;
  align-items: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
`;
const TabListItem = styled.li``;

const Image = styled.img`
  display: block;
  max-width: 100%;
  margin: 3px auto;
  background: #000396;
  width: 120px;
  height: 60px;
`;
const BuddyGroups = styled.div`
  padding-top: 4px;
  padding-left: 2px;
`;
const BuddyGroup = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;
const BuddyText = styled.span`
  padding: 2px;
`;
const BuddyItem = styled.button`
  appearance: none;
  margin: 0;
  background-color: transparent;
  border: 0;
  font: inherit;
  padding: 2px 4px 2px 15px;
  margin-left: 20px;

  &:focus {
    ${focusStyles}
  }
`;
const BuddyGroupItem = styled.button<{ isExpanded: boolean }>`
  appearance: none;
  background-color: transparent;
  font: inherit;
  position: relative;
  padding: 3px 4px 3px 18px;

  &:focus ${BuddyText} {
    ${focusStyles}
  }

  &::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    width: 0px;
    height: 0px;
    border-left: 4px solid #2f2f2f;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
  }

  ${(props) =>
    props.isExpanded
      ? `font-weight: bold;

  &::before {
    left: 4px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid #2f2f2f;
    border-bottom: 0;
    border-top: 5px solid #2f2f2f;
  }`
      : ""}
`;

export default BuddyList;
