import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ContextMenu from '../components/ContextMenu';

interface XandY {
  top: number;
  left: number;
}

const useContextMenu = () => {
  const [configState, setConfigState] = useState(null);

  const handleContextMenu = (
    e: React.MouseEvent<HTMLButtonElement>,
    config: any
  ) => {
    e.preventDefault();
    setConfigState(config);
    setShowContextMenu({ top: e.clientY, left: e.clientX });
  };

  const [contextMenu, setShowContextMenu] = useState<XandY | null>(null);

  const ContextMenuPortal = () => {
    return (
      <div>
        {contextMenu
          ? ReactDOM.createPortal(
              <ContextMenu
                linkSections={configState}
                style={{ left: contextMenu?.left, top: contextMenu?.top }}
                setShowContextMenu={setShowContextMenu}
              />,
              document.getElementById('modals') as HTMLElement
            )
          : null}
      </div>
    );
  };

  return {
    ContextMenuPortal,
    handleContextMenu,
  };
};

export default useContextMenu;
