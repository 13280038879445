import React from "react";
import styled from "styled-components";

const order = [
  "red",
  "red",
  "yellow",
  "yellow",
  "yellow",
  "yellow",
  "yellow",
  "yellow",
  "yellow",
  "green",
  "green",
  "green",
  "green",
  "green",
  "green",
];

const ProgressBar = ({ warningLevel }: { warningLevel: number }) => {
  return (
    <ProgressBarWrap>
      {order
        .filter(
          (_, index, arr) =>
            index < Math.round(arr.length / (100 / (100 - warningLevel)))
        )
        .map((color, index) => (
          <ProgressBlock key={index} color={color} />
        ))}
    </ProgressBarWrap>
  );
};

const ProgressBarWrap = styled.div`
  display: flex;
  width: 48px;
  height: 8px;
  border-top: 1px solid #a0a0a0;
  border-left: 1px solid #a0a0a0;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  margin-top: 4px;
  background: black;
  padding: 1px;
`;
const ProgressBlock = styled.div`
  width: 2px;
  height: 100%;
  & + & {
    margin-left: 1px;
  }
  background-color: ${(props) => props.color};
`;

export default ProgressBar;
