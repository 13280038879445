import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Window from "./Window";
import DetailsSection from "./DetailsSection";
import Checkbox from "./FormCheckbox/Checkbox";
import InputText from "./FormInputText/InputText";
import SelectBox from "./FormSelectBox/SelectBox";
import { FontSize, InlineStyles, setState } from "../types";

const fonts = `
  Times New Roman
  Verdana
  Trebuchet MS
  Tahoma
  Helvetica
  Arial
  Georgia
  Garamond
  Courier New
  Brush Script MT
`
  .split("\n")
  .map((font) => font.trim())
  .filter(Boolean)
  .sort();

type SupportedFonts =
  | "Times New Roman"
  | "Verdana"
  | "Trebuchet MS"
  | "Tahoma"
  | "Helvetica"
  | "Arial"
  | "Georgia"
  | "Garamond"
  | "Courier New"
  | "Brush Script MT";

const fontOptions = fonts.map((font) => ({
  title: font,
  value: font,
  style: { fontFamily: font },
  isSelected: font === "Times New Roman",
}));

interface Props {
  onClose: () => void;
  setInlineStyle: setState<InlineStyles>;
  selectedFont: string;
  selectedFontSize: number;
  fontSizesArray: FontSize[];
}

export default function FontPicker({
  onClose,
  setInlineStyle,
  selectedFont,
  selectedFontSize,
  fontSizesArray,
}: Props) {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [currentFont, setCurrentFont] = useState(
    selectedFont || "Times New Roman"
  );
  const [currentFontSize, setCurrentFontSize] = useState(
    selectedFontSize || 16
  );

  const optionRefs = useRef<HTMLButtonElement[]>([]);

  const fontSizes = fontSizesArray.map((fontSize) => ({
    title: fontSize,
    value: fontSize,
    isSelected: fontSize === 16,
  }));

  useEffect(() => {
    // scroll to the default font ('Times New Roman') on mount
    const fontMatchIndex = fonts.findIndex((font) =>
      font.toLowerCase().startsWith(currentFont.toLowerCase())
    );
    if (fontMatchIndex >= 0) {
      scrollableRef.current!.scrollTop =
        optionRefs.current[fontMatchIndex].offsetTop;
    }
  }, []);

  function handleFontUpdate(fontVal: SupportedFonts, ref: HTMLButtonElement) {
    setCurrentFont(fontVal);
    setInlineStyle((prevInlineStyle: InlineStyles) => ({
      ...prevInlineStyle,
      fontFamily: fontVal,
    }));
    scrollableRef.current!.scrollTop = ref.offsetTop;
  }

  function handleFontSizeUpdate(fontSize: FontSize) {
    setCurrentFontSize(fontSize);
    setInlineStyle((prevInlineStyle: InlineStyles) => ({
      ...prevInlineStyle,
      fontSize,
    }));
  }

  function handleFontSearch(value: string) {
    // the first font that matches our search, scroll to it
    const fontSize = fonts.findIndex((font) =>
      font.toLowerCase().startsWith(value.toLowerCase())
    );
    if (fontSize >= 0) {
      scrollableRef.current!.scrollTop = optionRefs.current[fontSize].offsetTop;
    }

    // if we type in the full name of the font, it selects it
    const perfectFontMatch = fonts.find(
      (x) => x.toLowerCase() === value.toLowerCase()
    );
    if (perfectFontMatch) {
      setCurrentFont(perfectFontMatch);
    }
  }

  function handleFontSizeSearch(value: string) {
    // if we type in the full name of the fontSize, it selects it
    const perfectFontSize = fontSizesArray.find((x) => x === Number(value));
    if (perfectFontSize) {
      setCurrentFontSize(perfectFontSize);
    }
  }

  return (
    <Window title="Font" navActionList={["close"]} onClose={onClose}>
      <InnerWrap>
        <InputsWrap>
          <Section>
            <Label htmlFor="font">Font:</Label>
            <InputTextFont
              id="font"
              name="Font"
              value={currentFont}
              onChange={handleFontSearch}
            />
            <SelectBoxFancy
              /* @ts-ignore */
              options={fontOptions}
              onClick={handleFontUpdate}
              ref={scrollableRef}
              optionRefs={optionRefs}
              selectedOption={currentFont}
            />
          </Section>
          <Section>
            <Label htmlFor="fontStyle">Font Style:</Label>
            <InputTextFontStyle id="fontStyle" name="fontStyle" />
            {/* @ts-ignore */}
            <SelectBoxFancy options={[]} onClick={handleFontSizeUpdate} />
          </Section>
          <Section>
            <Label htmlFor="fontSize">Size:</Label>
            <InputTextFontSize
              id="fontSize"
              name="fontSize"
              onChange={handleFontSizeSearch}
            />
            <SelectBoxFancy
              /* @ts-ignore */
              options={fontSizes}
              onClick={handleFontSizeUpdate}
              selectedOption={currentFontSize}
            />
          </Section>
        </InputsWrap>
        <Flex>
          <DetailsSection title="Effects">
            <CheckboxElem label="Strikeout" />
            <CheckboxElem label="Underline" />
          </DetailsSection>
          <DetailsSectionElem title="Sample">
            <span style={{ fontFamily: currentFont }}>AaBbYyZz</span>
          </DetailsSectionElem>
        </Flex>
      </InnerWrap>
    </Window>
  );
}

const Section = styled.div`
  margin: 0 4px;
`;
const CheckboxElem = styled(Checkbox)`
  margin: 4px 0;
`;
const DetailsSectionElem = styled(DetailsSection)`
  width: 100%;
  text-align: center;

  line-height: 40px;

  .is-active {
    background-color: #050283;
  }

  > div {
    line-height: normal;
  }
`;
const SelectBoxFancy = styled(SelectBox)`
  font: inherit;
  > div {
    height: 97px;
    overflow-y: scroll;
  }
`;
const Flex = styled.div`
  display: flex;
`;
const InputTextFont = styled(InputText)`
  width: 145px;
`;
const InputTextFontStyle = styled(InputText)`
  width: 110px;
`;
const InputTextFontSize = styled(InputText)`
  width: 45px;
`;
const InputsWrap = styled.div`
  display: flex;
  margin: 8px;
`;
const InnerWrap = styled.div`
  min-width: 200px;
`;
const Label = styled.label`
  display: block;
`;
