import React, { useContext } from 'react';
import Login from './components/Login';
import BuddyList from './components/BuddyList';
import ChatWindow from './components/ChatWindow';
import AwayMessage from './components/AwayMessage';
import TaskBar from './components/TaskBar';
import './App.scss';
import { AIMContext } from './AIMProvider';
import usePreloadedSounds from './hooks/usePreloadedSounds';

function App() {
  const {
    loginStatus,
    awayMessage,
    openChatWindows,
    setOpenChatWindows,
    debugMode,
    skipLogin,
  } = useContext(AIMContext);

  usePreloadedSounds();

  return (
    <>
      {skipLogin || loginStatus === 'done' ? (
        <>
          <BuddyList />
        </>
      ) : (
        <>
          <Login />
        </>
      )}
      {awayMessage && <AwayMessage />}
      {openChatWindows?.map((title) => (
        <ChatWindow
          key={title}
          width={400}
          title={title}
          onClose={() => {
            setOpenChatWindows((prevOpenChatWindows: any) =>
              prevOpenChatWindows.filter(
                (chatTitle: any) => chatTitle !== title
              )
            );
          }}
        />
      ))}
      <TaskBar />
    </>
  );
}

export default App;
