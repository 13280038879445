import React, {
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from "react";
import ReactDOM from "react-dom";
import { EditorContent } from "@tiptap/react";
import styled from "styled-components";
import ContextMenu from "./ContextMenu";
import IconBarIcon from "./IconBarIcon";
import VerticalDivider from "./VerticalDivider";
import ColorPicker from "./ColorPicker";
import FontPicker from "./FontPicker";
import FontColorIcon from "../images/font_color.png";
import FontBgColorIcon from "../images/font_bg_color.png";
import FontSizeDecreaseIcon from "../images/font_size_decrease.png";
import FontSizeIncreaseIcon from "../images/font_size_increase.png";
import FontSizeDefaultIcon from "../images/font_size_default.png";
import FontBoldIcon from "../images/font_bold.png";
import FontItalicIcon from "../images/font_italic.png";
import FontUnderlineIcon from "../images/font_underline.png";
import LinkIcon from "../images/link.png";
import ImageIcon from "../images/image.png";
import Smilies from "./Smilies";
import { FontSize, InlineStyles } from "../types";

const fontSizesArray: FontSize[] = [8, 10, 12, 14, 16, 18, 24, 38];

interface Props {
  inlineStyle: InlineStyles;
  setInlineStyle: Dispatch<SetStateAction<InlineStyles>>;
  editor: any;
  onKeyUp?: Function;
}

interface XandY {
  top: number;
  left: number;
}

function TextFormatBar({
  inlineStyle,
  setInlineStyle,
  editor,
  onKeyUp,
}: Props) {
  const [contextMenu, setShowContextMenu] = useState<XandY | null>(null);
  const [showFontPicker, setShowFontPicker] = useState(false);
  const [colorPickerType, setColorPickerType] = useState("");

  const { color, bgColor, fontFamily, bold, italic, underline, fontSize } =
    inlineStyle;

  function handleContextMenu(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    setShowContextMenu({ top: e.clientY, left: e.clientX });
  }

  function handleFontUpdate() {
    setShowFontPicker(true);
  }

  function handleFontSizeIncrease() {
    setInlineStyle((prevStyles) => {
      let indexOfNextFont =
        fontSizesArray.findIndex(
          (fontSize) => fontSize === prevStyles.fontSize
        ) + 1;
      if (indexOfNextFont > fontSizesArray.length - 1) {
        indexOfNextFont -= 1;
      }
      editor
        .chain()
        .focus()
        .setColor(color, bgColor, fontFamily, fontSizesArray[indexOfNextFont])
        .run();
      return { ...prevStyles, fontSize: fontSizesArray[indexOfNextFont] };
    });
  }

  function handleFontSizeDefault() {
    setInlineStyle((prevStyles) => {
      editor.chain().focus().setColor(color, bgColor, fontFamily, 16).run();
      return { ...prevStyles, fontSize: 16 };
    });
  }

  function handleFontSizeDecrease() {
    setInlineStyle((prevStyles) => {
      let indexOfPrevFont =
        fontSizesArray.findIndex(
          (fontSize) => fontSize === prevStyles.fontSize
        ) - 1;
      if (indexOfPrevFont < 0) {
        indexOfPrevFont += 1;
      }
      editor
        .chain()
        .focus()
        .setColor(color, bgColor, fontFamily, fontSizesArray[indexOfPrevFont])
        .run();
      return { ...prevStyles, fontSize: fontSizesArray[indexOfPrevFont] };
    });
  }

  function handleBoldToggle() {
    editor.chain().focus().toggleBold().run();
    setInlineStyle((prevStyles) => {
      return { ...prevStyles, bold: !prevStyles.bold };
    });
  }

  function handleItalicToggle() {
    editor.chain().focus().toggleItalic().run();
    setInlineStyle((prevStyles) => {
      return { ...prevStyles, italic: !prevStyles.italic };
    });
  }

  function handleUnderlineToggle() {
    editor.chain().focus().toggleUnderline().run();
    setInlineStyle((prevStyles) => {
      return { ...prevStyles, underline: !prevStyles.underline };
    });
  }

  function handleLink() {
    editor.commands.setLink({ href: "https://example.com", target: "_blank" });
  }

  return (
    <>
      <IconBarWrap>
        <IconBarIcon
          icon={FontColorIcon}
          width={9}
          height={8}
          onClick={() => {
            setColorPickerType("color");
          }}
        />
        <IconBarIcon
          icon={FontBgColorIcon}
          width={13}
          height={10}
          onClick={() => {
            setColorPickerType("bgColor");
          }}
        />
        <VerticalDivider />
        <IconBarIcon
          icon={FontSizeDecreaseIcon}
          width={13}
          height={8}
          onClick={handleFontSizeDecrease}
        />
        <IconBarIcon
          icon={FontSizeDefaultIcon}
          width={9}
          height={8}
          onClick={handleFontSizeDefault}
        />
        <IconBarIcon
          icon={FontSizeIncreaseIcon}
          width={15}
          height={10}
          onClick={handleFontSizeIncrease}
        />
        <VerticalDivider />
        <IconBarIcon
          icon={FontBoldIcon}
          isSelected={bold}
          width={8}
          height={9}
          onClick={handleBoldToggle}
        />
        <IconBarIcon
          icon={FontItalicIcon}
          isSelected={italic}
          width={12}
          height={9}
          onClick={handleItalicToggle}
        />
        <IconBarIcon
          icon={FontUnderlineIcon}
          isSelected={underline}
          width={9}
          height={9}
          onClick={handleUnderlineToggle}
        />
        <VerticalDivider />
        <IconBarIcon
          onClick={handleLink}
          icon={LinkIcon}
          width={17}
          height={11}
        />
        <IconBarIcon icon={ImageIcon} width={14} height={11} />
        <Smilies editor={editor} inlineStyles={inlineStyle} />
      </IconBarWrap>
      <TextArea>
        <EditorContentDiv
          onKeyUp={onKeyUp}
          editor={editor}
          onContextMenu={handleContextMenu}
        />
      </TextArea>
      {contextMenu &&
        ReactDOM.createPortal(
          <ContextMenu
            linkSections={[
              [
                {
                  name: "Insert",
                },
                {
                  name: "Text",
                  items: [
                    [
                      {
                        name: "Font...",
                        onClick: handleFontUpdate,
                      },
                    ],
                  ],
                },
              ],
            ]}
            style={{ left: contextMenu?.left, top: contextMenu?.top }}
            setShowContextMenu={setShowContextMenu}
          />,
          document.getElementById("modals") as HTMLElement
        )}
      {colorPickerType &&
        ReactDOM.createPortal(
          <ColorPicker
            type={colorPickerType}
            setInlineStyle={setInlineStyle}
            inlineStyle={inlineStyle}
            editor={editor}
            onClose={() => {
              setColorPickerType("");
              editor.chain().focus().run();
            }}
          />,
          document.getElementById("modals") as HTMLElement
        )}
      {showFontPicker &&
        ReactDOM.createPortal(
          <FontPicker
            onClose={() => {
              editor
                .chain()
                .focus()
                .setColor(color, bgColor, fontFamily, fontSize)
                .run();
              setShowFontPicker(false);
            }}
            setInlineStyle={setInlineStyle}
            selectedFont={fontFamily}
            selectedFontSize={fontSize}
            fontSizesArray={fontSizesArray}
          />,
          document.getElementById("modals") as HTMLElement
        )}
    </>
  );
}

const EditorContentDiv = styled(EditorContent)<any>`
  height: 100%;
  min-height: 62px;

  > .ProseMirror {
    min-height: 62px;
    height: 100%;
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  p {
    margin: 0;
  }
`;

const IconBarWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  box-shadow: inset -1px -1px #dfdfdf, inset 1px 1px grey;
  margin: 5px 0 2px;
`;

const TextArea = styled.div`
  position: relative;
  min-height: 62px;
  flex-grow: 1;
  background-color: #fff;
  margin: 3px 0 4px;
  box-shadow: inset 2px 2px 1px #000;
  resize: none;
  font: inherit;
  appearance: none;
  width: 100%;
  border: 0;
  border-radius: 0;
  font-family: Times New Roman, serif;
  font-size: 16px;
  cursor: text;

  &:focus {
    outline: none;
  }

  p {
    line-height: 1.07;
    word-break: break-word;
  }
`;

export default TextFormatBar;
