import styled from 'styled-components';

const VerticalDivider = styled.hr`
  width: 2px;
  border-left: 1px solid #848484;
  border-right: 1px solid #fff;
  height: calc(100% - 2px);
  margin: 0 3px;
  align-self: flex-end;
`;

export default VerticalDivider;