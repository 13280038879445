import React, { Fragment, useEffect, useRef, useState } from "react";
import styled, { CSSProperties } from "styled-components";
import Divider from "./Divider";
import useGenericClickOutside from "../useGenericClickOutside";

interface LinkSection {
  name: string;
  items?: LinkSection[][];
  onClick?: Function;
}

interface Props {
  linkSections: LinkSection[][] | null;
  style?: CSSProperties;
  setShowContextMenu: Function;
  isTopNav?: boolean;
}

interface ContextMenuElementProps {
  isTopNav?: boolean;
}

export default function ContextMenu({
  linkSections,
  style,
  setShowContextMenu,
  isTopNav = false,
}: Props) {
  const menuRef = useRef(null);
  useGenericClickOutside(menuRef, () => {
    setShowContextMenu(false);
  });

  return (
    <ContextMenuElem style={style} ref={menuRef} isTopNav={isTopNav}>
      {linkSections?.map((section, index) => (
        <Fragment key={`LinkSection${index}`}>
          {index !== 0 && <Divider margin="4px 0" />}
          <List>
            {section.map((item) => (
              <SectionItem
                item={item}
                isTopNav={isTopNav}
                setShowContextMenu={setShowContextMenu}
              />
            ))}
          </List>
        </Fragment>
      ))}
    </ContextMenuElem>
  );
}

const SectionItem = ({
  item,
  isTopNav,
  setShowContextMenu,
}: {
  item: LinkSection;
  isTopNav: boolean;
  setShowContextMenu: Function;
}) => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const isTouchScreen = window?.matchMedia?.("(pointer: coarse)")?.matches;

  function handleClickItem(item: LinkSection) {
    if (item.items?.length) {
      setShowSubMenu(true);
    } else {
      if (item?.onClick) {
        item.onClick();
      }
      setShowContextMenu(false);
    }
  }

  function handlePointerOver(item: LinkSection) {
    if (item.items?.length) {
      setShowSubMenu(true);
    }
  }

  useEffect(() => {
    return () => {
      setShowSubMenu(false);
    };
  }, []);
  return (
    <React.Fragment key={item.name}>
      <MenuSection>
        <ListItem
          isTopNav={isTopNav}
          onPointerLeave={() => {
            if (!isTouchScreen) {
              setShowSubMenu(false);
            }
          }}
        >
          <Button
            onClick={() => handleClickItem(item)}
            onPointerOver={() => handlePointerOver(item)}
            className="ContextMenuButton"
          >
            {item.name}
            {item.items?.length && (
              <Arrow
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 407.436 407.436"
                style={
                  {
                    enableBackground: "new 0 0 407.436 407.436",
                  } as CSSProperties
                }
              >
                <polygon points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 " />
              </Arrow>
            )}
          </Button>
          {item.items && showSubMenu && (
            <ContextMenu
              key={item.items[0][0].name}
              linkSections={item.items}
              setShowContextMenu={setShowContextMenu}
              isTopNav={isTopNav}
            />
          )}
        </ListItem>
      </MenuSection>
    </React.Fragment>
  );
};

const MenuSection = styled.div``;
const ListItem = styled.li<ContextMenuElementProps>`
  position: relative;
  list-style: none;

  ${(props) => (props.isTopNav ? "white-space: nowrap" : "")}
`;
const List = styled.ul`
  margin: 0;
  padding: 0;
`;
const ContextMenuElem = styled.div<ContextMenuElementProps>`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #c0c0c0;
  z-index: 99999; /* sit on top of isDialog windows */
  padding: 4px;
  box-shadow: inset -1px -1px 0px #0c0c0c, inset 1px 1px 0px #bbc3c4,
    inset -2px -2px 0px #808088, inset 2px 2px 0px #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);

  ${(props) =>
    props.isTopNav ? "position: absolute; left: 0; top: 100%;" : ""}

  & & {
    position: absolute;
    left: calc(100% - 3px);
    top: 0;
  }
`;

const Button = styled.button`
  position: relative;
  display: block;
  width: 100%;
  padding: 4px 20px;
  margin: 0;
  border: 0;
  background-color: transparent;
  appearance: none;
  text-align: left;

  &:hover,
  &:focus {
    background-color: #050283;
    color: #fff;
  }
`;

const Arrow = styled.svg`
  width: 8px;
  height: 8px;
  position: absolute;
  right: 4px;
  top: calc(50% - 1px);
  transform: translateY(-50%);
  fill: currentColor;
`;
