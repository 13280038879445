import React, { useContext } from "react";
import styled from "styled-components";
import { AIMContext } from "../AIMProvider";
import ButtonForm from "./ButtonForm";

export const BuddyIconGrid = () => {
  console.log("grid");
  const { setBuddyIconSrc } = useContext(AIMContext);

  return (
    <Grid>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-add.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-add.gif`}
          alt={"badassbuddy_com-add"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-afro.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-afro.gif`}
          alt={"badassbuddy_com-afro"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-apcircle.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-apcircle.gif`}
          alt={"badassbuddy_com-apcircle"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-beanbag.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-beanbag.gif`}
          alt={"badassbuddy_com-beanbag"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-blink182.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-blink182.gif`}
          alt={"badassbuddy_com-blink182"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-bored.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-bored.gif`}
          alt={"badassbuddy_com-bored"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-brb.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-brb.gif`}
          alt={"badassbuddy_com-brb"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-chappelle.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-chappelle.gif`}
          alt={"badassbuddy_com-chappelle"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-chores.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-chores.gif`}
          alt={"badassbuddy_com-chores"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-cs.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-cs.gif`}
          alt={"badassbuddy_com-cs"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-ddr.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-ddr.gif`}
          alt={"badassbuddy_com-ddr"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-deepthough.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-deepthough.gif`}
          alt={"badassbuddy_com-deepthough"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-do_the_dew.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-do_the_dew.gif`}
          alt={"badassbuddy_com-do_the_dew"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-dodgeball.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-dodgeball.gif`}
          alt={"badassbuddy_com-dodgeball"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-drevil.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-drevil.gif`}
          alt={"badassbuddy_com-drevil"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-dropit.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-dropit.gif`}
          alt={"badassbuddy_com-dropit"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-duck.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-duck.gif`}
          alt={"badassbuddy_com-duck"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-emokids.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-emokids.gif`}
          alt={"badassbuddy_com-emokids"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-et.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-et.gif`}
          alt={"badassbuddy_com-et"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-fart2.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-fart2.gif`}
          alt={"badassbuddy_com-fart2"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-firstkiss.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-firstkiss.gif`}
          alt={"badassbuddy_com-firstkiss"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-greenday.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-greenday.gif`}
          alt={"badassbuddy_com-greenday"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-guitarhero.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-guitarhero.gif`}
          alt={"badassbuddy_com-guitarhero"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-hackysack.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-hackysack.gif`}
          alt={"badassbuddy_com-hackysack"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-halfbaked.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-halfbaked.gif`}
          alt={"badassbuddy_com-halfbaked"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-halo2.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-halo2.gif`}
          alt={"badassbuddy_com-halo2"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-homestar.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-homestar.gif`}
          alt={"badassbuddy_com-homestar"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-hoola-hoop.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-hoola-hoop.gif`}
          alt={"badassbuddy_com-hoola-hoop"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-icp.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-icp.gif`}
          alt={"badassbuddy_com-icp"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-iraqwar.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-iraqwar.gif`}
          alt={"badassbuddy_com-iraqwar"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-jumpleaves.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-jumpleaves.gif`}
          alt={"badassbuddy_com-jumpleaves"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-kickbutt2.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-kickbutt2.gif`}
          alt={"badassbuddy_com-kickbutt2"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-korn.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-korn.gif`}
          alt={"badassbuddy_com-korn"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-l33t.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-l33t.gif`}
          alt={"badassbuddy_com-l33t"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-liljon2.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-liljon2.gif`}
          alt={"badassbuddy_com-liljon2"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-limpbizkit.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-limpbizkit.gif`}
          alt={"badassbuddy_com-limpbizkit"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-linkinpark.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-linkinpark.gif`}
          alt={"badassbuddy_com-linkinpark"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-lol!.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-lol!.gif`}
          alt={"badassbuddy_com-lol!"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-maceme.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-maceme.gif`}
          alt={"badassbuddy_com-maceme"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-mandy.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-mandy.gif`}
          alt={"badassbuddy_com-mandy"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-matrix2.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-matrix2.gif`}
          alt={"badassbuddy_com-matrix2"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-metalhead.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-metalhead.gif`}
          alt={"badassbuddy_com-metalhead"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-metallica.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-metallica.gif`}
          alt={"badassbuddy_com-metallica"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-monkeyspank.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-monkeyspank.gif`}
          alt={"badassbuddy_com-monkeyspank"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-moonwalk.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-moonwalk.gif`}
          alt={"badassbuddy_com-moonwalk"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-mychemical.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-mychemical.gif`}
          alt={"badassbuddy_com-mychemical"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-nutcheck.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-nutcheck.gif`}
          alt={"badassbuddy_com-nutcheck"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-offspring.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-offspring.gif`}
          alt={"badassbuddy_com-offspring"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-outkast.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-outkast.gif`}
          alt={"badassbuddy_com-outkast"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-p!nk.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-p!nk.gif`}
          alt={"badassbuddy_com-p!nk"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-paintball.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-paintball.gif`}
          alt={"badassbuddy_com-paintball"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-parentsuk2.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-parentsuk2.gif`}
          alt={"badassbuddy_com-parentsuk2"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-popmacolla.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-popmacolla.gif`}
          alt={"badassbuddy_com-popmacolla"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-pothead.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-pothead.gif`}
          alt={"badassbuddy_com-pothead"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-pyro.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-pyro.gif`}
          alt={"badassbuddy_com-pyro"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-relatives.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-relatives.gif`}
          alt={"badassbuddy_com-relatives"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-rustyslide.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-rustyslide.gif`}
          alt={"badassbuddy_com-rustyslide"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-schoolsout.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-schoolsout.gif`}
          alt={"badassbuddy_com-schoolsout"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-shootingst.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-shootingst.gif`}
          alt={"badassbuddy_com-shootingst"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-skate5.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-skate5.gif`}
          alt={"badassbuddy_com-skate5"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-slipnslide.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-slipnslide.gif`}
          alt={"badassbuddy_com-slipnslide"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-soad.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-soad.gif`}
          alt={"badassbuddy_com-soad"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-spaz.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-spaz.gif`}
          alt={"badassbuddy_com-spaz"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-spongebob2.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-spongebob2.gif`}
          alt={"badassbuddy_com-spongebob2"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-sum41.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-sum41.gif`}
          alt={"badassbuddy_com-sum41"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-taken.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-taken.gif`}
          alt={"badassbuddy_com-taken"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-wildmonkey.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-wildmonkey.gif`}
          alt={"badassbuddy_com-wildmonkey"}
          width="48"
          height="48"
        />
      </ButtonForm>
      <ButtonForm
        onClick={() =>
          setBuddyIconSrc(`images/buddy_icons/badassbuddy_com-zit.gif`)
        }
      >
        <img
          src={`images/buddy_icons/badassbuddy_com-zit.gif`}
          alt={"badassbuddy_com-zit"}
          width="48"
          height="48"
        />
      </ButtonForm>
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding: 2px 0px 0px 2px;
  gap: 0px;
`;
