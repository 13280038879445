import React, { useContext } from "react";
import PropTypes from "prop-types";
import Notifications from "./Notifications";
import StartMenu from "../StartMenu";
import ButtonProgram from "../ButtonProgram";
import ButtonIconSmall from "../ButtonIconSmall";
import { AIMContext } from "../../AIMProvider";

import "./_TaskBar.scss";

const TaskBar = (props) => {
  const { mountedOrder } = useContext(AIMContext);
  return (
    <div className="TaskBar">
      <StartMenu className="TaskBar__start" options={props.options} />
      {props.quickLaunch && (
        <div className="TaskBar__quick-launch">
          {props.quickLaunch.map((qlEntry) => (
            <ButtonIconSmall
              key={`${qlEntry.icon}-QuickLaunch`}
              title={qlEntry.title}
              onClick={qlEntry.onClick}
              icon={qlEntry.icon}
            />
          ))}
        </div>
      )}
      <div className="TaskBar__programs">
        {mountedOrder.length > 0 &&
          mountedOrder.map((openWindow) => {
            return (
              <ButtonProgram
                isActive={openWindow.isActive}
                onClick={() => {
                  openWindow.onClick(openWindow.isActive);
                }}
                icon={openWindow.icon}
                key={`${openWindow.icon}-ButtonProgram-${openWindow.title}-${openWindow.id}`}
                data-testid={`TaskBarButton-${openWindow.title}`}
              >
                {openWindow.title}
              </ButtonProgram>
            );
          })}
      </div>
      <Notifications notifiers={props.notifiers} />
    </div>
  );
};

TaskBar.defaultProps = {
  openWindows: [],
  notifiers: [],
  quickLaunch: [],
  options: [],
};

TaskBar.propTypes = {
  options: PropTypes.array,
  quickLaunch: PropTypes.arrayOf(PropTypes.shape(ButtonIconSmall.propTypes)),
  openWindows: PropTypes.arrayOf(PropTypes.shape(ButtonProgram.propTypes)),
  notifiers: PropTypes.arrayOf(PropTypes.shape(Notifications.propsTypes)),
};

export default TaskBar;
