import TextStyle from "@tiptap/extension-text-style";
import "@tiptap/extension-text-style";

const SpanClass = TextStyle.extend({
  addAttributes() {
    return {
      class: {
        default: "",
      },
    };
  },
});

export default SpanClass;
