import React, { useContext } from "react";
import styled from "styled-components";
import { generateHTML } from "@tiptap/html";
import Window from "./Window";
import Checkbox from "./FormCheckbox/Checkbox";
import AIMHeaderIconOg from "../images/aim_header_icon.png";
import AIMHeaderIcon from "../images/aim_header_icon_new.png";
import { AIMContext } from "../AIMProvider";
import Document from "@tiptap/extension-document";
import Italic from "@tiptap/extension-italic";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Underline from "@tiptap/extension-underline";
import TextStyle from "@tiptap/extension-text-style";
import Color from "./Color";

interface TabContentProps {
  hasScroll?: boolean;
}

function AwayMessage() {
  const { awayMessage, setAwayMessage, debugMode } = useContext(AIMContext);
  // @ts-ignore
  const { json, text } = awayMessage || {};

  return (
    <Window
      title={text || "Away Message"}
      icon={debugMode ? AIMHeaderIconOg : AIMHeaderIcon}
      onClose={() => setAwayMessage(null)}
      width={300}
    >
      <Wrapper>
        <p>
          Because the Accept Message dialog is enabled, the <br />
          following will only be sent to users on your Buddy List.
        </p>
        <TabContentWrap>
          <TabContent>
            <span
              dangerouslySetInnerHTML={{
                __html: json,
              }}
            ></span>
          </TabContent>
        </TabContentWrap>
        <p>0 messages received from 0 people while being Away</p>
        <TabContentWrap>
          <TabContent hasScroll></TabContent>
        </TabContentWrap>
        <Flex>
          <Footnote>Click the 'I'm Back' button to quit being Away</Footnote>
          <LabelWrapper>
            <div>
              <LabelWrap>
                <Checkbox label="Hide windows while I'm Away" />
              </LabelWrap>
              <LabelWrap>
                <Checkbox label="Disable sounds while I'm Away" />
              </LabelWrap>
            </div>
            <ActionButton
              onClick={() => {
                setAwayMessage(null);
              }}
            >
              I'm Back
            </ActionButton>
          </LabelWrapper>
        </Flex>
      </Wrapper>
    </Window>
  );
}

const ActionButton = styled.button`
  margin: 0px 10px;
  min-width: 48px;
  outline-width: 1px;
  outline-offset: -5px;
  padding: 5px 16px;
  box-shadow: inset -1px -1px 0px #0c0c0c, inset 1px 1px 0px #ffffff,
    inset -2px -2px 0px #808088, inset 2px 2px 0px #bbc3c4;
  border: 0px solid transparent;
  background-color: #bbc3c4;
  position: relative;
  user-select: none;
  font: inherit;
  appearance: none;

  &:focus {
    outline: 1px dotted #000;
    outline-offset: -4px;
  }
`;
const Wrapper = styled.div`
  padding: 0 6px;

  p {
    margin: 4px 0 8px;
  }
`;
const Flex = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 6px;
`;
const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const LabelWrap = styled.div`
  margin: 2px 0;
`;
const Footnote = styled.div`
  margin-bottom: 0;
`;
const TabContent = styled.div<TabContentProps>`
  position: relative;
  height: 102px;
  min-width: 275px;
  background-color: #fff;
  box-shadow: inset 2px 2px 1px #000;
  resize: none;
  font: inherit;
  appearance: none;
  padding: 6px;
  border: 0;
  border-radius: 0;
  font-family: Times New Roman, serif;
  font-size: 16px;
  overflow-y: auto;
  flex-grow: 1;

  &,
  & * {
    user-select: initial;
  }
  ${(props) => (props.hasScroll ? "overflow-y: scroll; height: 50px;" : "")}

  p {
    margin: 0;
  }

  &:focus {
    outline: none;
  }
`;
const TabContentWrap = styled.div`
  position: relative;
  background-color: #c0c0c0;
  box-shadow: 1px 1px 1px #030300;
  margin-top: 6px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 16px);
    height: 100%;
    box-shadow: inset 2px 2px 1px #000;
    pointer-events: none;
  }
`;

export default AwayMessage;
