import styled from 'styled-components';

interface Props {
  margin?: string;
}

const Divider = styled.hr<Props>`
  height: 2px;
  border-top: 1px solid #848484;
  border-left: 1px solid #848484;
  border-bottom: 1px solid #fff;
  margin: ${props => props.margin || 0};
`;

export default Divider;