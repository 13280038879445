import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './_SelectBox.scss';

const SelectBox = React.forwardRef((props, scrollableRef) => {
  const Comp = props.component ? props.component : 'button';
  const getOptionRef = (el, index) => {
    if (props.optionRefs) {
      props.optionRefs.current[index] = el
    }
  }

  return (
    <div
      className={cx(
        `SelectBox ${props.className}`,
        props.component
          ? `SelectBox--${props.component.name}`
          : 'SelectBox--simple',
        { disabled: props.isDisabled }
      )}
    >
      <div ref={scrollableRef}>
        {props.options.map((option, index) => (
          <Comp
            key={
              typeof option.value !== 'object'
                ? option.value
                : JSON.stringify(option.value)
            }
            onClick={() => {
              if (props.optionRefs?.current[index]) {
                props.onClick(option.value, props.optionRefs.current[index])
              } else {
                props.onClick(option.value)
              }
            }}
            alt={props.component ? option.alt : undefined}
            className={cx(option.className, {
              'is-active': props.selectedOption === option.value,
            })}
            icon={props.component ? option.icon : undefined}
            title={
              option.title ||
              (typeof option.value === 'string' ? option.value : '')
            }
            value={option.value}
            ref={(el) => getOptionRef(el, index)}
            style={option.style}
          />
        ))}
      </div>
    </div>
  );
});

SelectBox.propTypes = {
  component: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      title: PropTypes.string,
      icon: PropTypes.string,
      alt: PropTypes.string,
      className: PropTypes.string,
      ref: PropTypes.object,
    })
  ),
};

export default SelectBox;
