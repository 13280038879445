import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AIMContext } from "../AIMProvider";
import Window from "./Window";
import Checkbox from "./FormCheckbox/Checkbox";
import TextFormatBar from "./TextFormatBar";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Color from "./Color";
import SpanClass from "./SpanClass";
import { emojiReplace } from "./MyEditor";

function AwayMessageCreator({
  setOpenAwayMessage,
}: {
  setOpenAwayMessage: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [inlineStyle, setInlineStyle] = useState({
    color: "",
    bgColor: "",
    fontFamily: "Times New Roman",
    fontSize: 16,
    bold: false,
    italic: false,
    underline: false,
  });
  const editor = useEditor({
    extensions: [StarterKit as any, Underline, Color, SpanClass, Link],
    content: "",
  });
  const { setAwayMessage, username } = useContext(AIMContext);

  return (
    <Window
      title="Edit Away Message"
      navActionList={["close"]}
      onClose={() => setOpenAwayMessage(false)}
    >
      <Wrapper>
        <Flex>
          <Label htmlFor="enterLabel">Enter label:</Label>
          <input id="enterLabel" type="text" />
        </Flex>
        <LabelAway>Enter new Away message:</LabelAway>
        <TextBoxWrap>
          <TextFormatBar
            editor={editor}
            inlineStyle={inlineStyle}
            setInlineStyle={setInlineStyle}
          />
        </TextBoxWrap>
        <InfoWrap>
          <Flex>
            <div>
              <p>Special Characters:</p>
              <p>
                <Char>%n</Char> = &nbsp;Screen Name of Buddy
              </p>
              <p>
                <Char>%d</Char> = &nbsp;Current Date
              </p>
              <p>
                <Char>%t</Char> = &nbsp;Current time
              </p>
            </div>
            <CheckboxWrap>
              <Checkbox label="Save for later use" />
            </CheckboxWrap>
          </Flex>
        </InfoWrap>
        <ActionsWrap>
          <ActionButton
            onClick={() => {
              setAwayMessage(() => {
                // save this when user hits "I'm Away"
                const html = editor?.getHTML();
                const updatedHTML = html!
                  .replaceAll("%n", username)
                  .replaceAll("%d", new Date().toLocaleDateString())
                  .replaceAll("%t", new Date().toLocaleTimeString());
                return {
                  json: emojiReplace(updatedHTML),
                  text: editor?.state.doc.textContent
                    .replaceAll("%n", username)
                    .replaceAll("%d", new Date().toLocaleDateString())
                    .replaceAll("%t", new Date().toLocaleTimeString()),
                };
              });
              setOpenAwayMessage(false);
            }}
          >
            I'm Away
          </ActionButton>
          <ActionButton onClick={() => {}}>Cancel</ActionButton>
        </ActionsWrap>
      </Wrapper>
    </Window>
  );
}

const TextBoxWrap = styled.div`
  width: 275px;
`;
const Char = styled.span`
  width: 19px;
  display: inline-block;
`;
const ActionsWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 8px;
`;
const ActionButton = styled.button`
  margin: 0px 10px;
  min-width: 48px;
  outline-width: 1px;
  outline-offset: -5px;
  padding: 5px 16px;
  box-shadow: inset -1px -1px 0px #0c0c0c, inset 1px 1px 0px #ffffff,
    inset -2px -2px 0px #808088, inset 2px 2px 0px #bbc3c4;
  border: 0px solid transparent;
  background-color: #bbc3c4;
  position: relative;
  user-select: none;
  font: inherit;
  appearance: none;

  &:focus {
    outline: 1px dotted #000;
    outline-offset: -4px;
  }
`;
const CheckboxWrap = styled.div`
  align-self: flex-end;
`;
const Wrapper = styled.div`
  padding: 12px 16px;
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Label = styled.label`
  margin-right: 24px;
`;
const LabelAway = styled.label`
  display: block;
  margin: 15px 0 12px;
  margin-right: 24px;
`;
const InfoWrap = styled.div`
  margin: 12px 0;

  p {
    margin: 0;
  }
  p + p {
    margin: 4px 0 0;
  }
`;

export default AwayMessageCreator;
