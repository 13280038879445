import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { AIMContext } from "../AIMProvider";
import TextFormatBar from "./TextFormatBar";
import VerticalDivider from "./VerticalDivider";
import { useEditor } from "@tiptap/react";
import SignUpWindow from "./SignUpWindow";
import { generateHTML } from "@tiptap/html";
import Document from "@tiptap/extension-document";
import Italic from "@tiptap/extension-italic";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Color from "./Color";
import SpanClass from "./SpanClass";
import Emoji from "./Emoji";
import HoverImgButton from "./HoverImgButton";
import ProgressBar from "./ProgressBar";
import WarnIcon from "../images/warn.png";
import WarnHoverIcon from "../images/warn_hover.png";
import BlockIcon from "../images/block.png";
import BlockHoverIcon from "../images/block_hover.png";
import AddBuddyIcon from "../images/add_buddy.png";
import AddBuddyHoverIcon from "../images/add_buddy_hover.png";
import GetInfoIcon from "../images/get_info_large.png";
import GetInfoHoverIcon from "../images/get_info_large_hover.png";
import SendIcon from "../images/send.png";
import SendActiveIcon from "../images/send_active.png";
import SendHoverIcon from "../images/send_hover.png";
// @ts-ignore
import SendSound from "../sounds/sent.mp3";
// @ts-ignore
import ReceivedSound from "../sounds/received.mp3";

const smileyText = {
  smile: ":-)",
  frown: ":-(",
  wink: ";-)",
  tongue: ":-P",
  oface: "=-O",
  kiss: ":-*",
  angry: ">:o",
  glasses: "8-)",
  money: ":-$",
  foot: ":-!",
  embarassed: ":-[",
  halo: "O:-)",
  slant: ":-/",
  cry: `:'(`,
  silent: ":-X",
  grin: ":-D",
};

function playSound(sound: any, soundEffect: any) {
  // later on when you actually want to play a sound at any point without user interaction
  if (soundEffect?.src) {
    soundEffect.src = sound;
  }

  soundEffect?.play();
}

export function emojiReplace(html: string) {
  return html
    .replaceAll(
      "O:-)",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--halo">&nbsp;</span> `
    )
    .replaceAll(
      ":-)",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--smile">&nbsp;</span> `
    )
    .replaceAll(
      ":-(",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--frown">&nbsp;</span> `
    )
    .replaceAll(
      ";-)",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--wink">&nbsp;</span> `
    )
    .replaceAll(
      ":-P",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--tongue">&nbsp;</span> `
    )
    .replaceAll(
      "=-O",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--oface">&nbsp;</span> `
    )
    .replaceAll(
      ":-*",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--kiss">&nbsp;</span> `
    )
    .replaceAll(
      "&gt;:o",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--angry">&nbsp;</span> `
    )
    .replaceAll(
      "8-)",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--glasses">&nbsp;</span> `
    )
    .replaceAll(
      ":-$",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--money">&nbsp;</span> `
    )
    .replaceAll(
      ":-!",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--foot">&nbsp;</span> `
    )
    .replaceAll(
      ":-[",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--embarassed">&nbsp;</span> `
    )
    .replaceAll(
      ":-/",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--slant">&nbsp;</span> `
    )
    .replaceAll(
      ":'(",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--cry">&nbsp;</span> `
    )
    .replaceAll(
      ":-X",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--silent">&nbsp;</span> `
    )
    .replaceAll(
      ":-D",
      ` <span style="outline: 1px solid transparent;" class="smiley smiley--grin">&nbsp;</span> `
    );
}

function parseEmojis(editor: any, setChatLog: Function) {
  // 1. parse HTML for emoji text and replace
  const html = editor?.getHTML();
  const updatedHTML = emojiReplace(html);

  // 2. setContent with new HTML from above
  // run onUpdate function on useEditor
  editor.commands.setContent(updatedHTML, true);

  // 4. update chat log with updated JSON
  setChatLog((prevLog: any) => {
    return [...prevLog, updatedHTML];
  });

  editor?.commands.clearContent(true);
}

function MyEditor({ title }: { title: string }) {
  const { username, soundEffects } = useContext(AIMContext);
  const [inlineStyle, setInlineStyle] = useState({
    color: "",
    bgColor: "",
    fontFamily: "Times New Roman",
    fontSize: 16,
    bold: false,
    italic: false,
    underline: false,
  });
  const scrollRef = useRef<HTMLDivElement>(null);
  const [chatLog, setChatLog] = useState<any[]>([]);
  const [openSignUpWindow, setOpenSignUpWindow] = useState<boolean>(false);

  const { color, bgColor, fontFamily, bold, italic, underline, fontSize } =
    inlineStyle;

  const editor = useEditor({
    extensions: [StarterKit as any, Underline, Color, SpanClass, Link],
    content: "",
  });

  function sendChat() {
    // @TODO use this to also make sure sned button is inactive until there's text content
    const isEmpty = editor?.state.doc.textContent.length === 0;

    if (isEmpty) {
      return;
    }

    playSound(SendSound, soundEffects?.[0]);

    parseEmojis(editor as any, setChatLog);

    // add all commands here when we send chat based on formatting state
    if (bold) {
      editor?.chain().setBold().run();
    }
    if (italic) {
      editor?.chain().setItalic().run();
    }
    if (underline) {
      editor?.chain().setUnderline().run();
    }

    editor
      ?.chain()
      .focus()
      // @ts-ignore
      .setColor(color, bgColor, fontFamily, fontSize)
      .run();

    // @TODO: for now let's just send a generic response back from SmarterChild
    setTimeout(() => {
      playSound(ReceivedSound, soundEffects?.[1]);
      setChatLog((prevLog) => [
        ...prevLog,
        {
          author: title,
          html: `<p>Hello there and welcome! I am but a simple bot at the moment!`,
        },
      ]);
    }, 1500);
  }

  function handleSubmit(e: React.KeyboardEvent) {
    if (e.key === "Enter") {
      sendChat();
    }
  }

  useEffect(() => {
    const xH = scrollRef.current!.scrollHeight || 0;
    scrollRef.current!.scrollTop = xH;
  }, [chatLog]);

  return (
    <>
      <TabContentWrap>
        <TabContent ref={scrollRef}>
          {chatLog.map((log, index) => {
            const chat = typeof log === "object" ? log?.html : log;

            return (
              <div key={index}>
                <UsernameText isReceived={Boolean(log?.author)}>
                  <strong>{log?.author || username}</strong>:
                </UsernameText>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: chat,
                  }}
                ></span>
                {index === 1 && (
                  <>
                    <br />
                    Sign up for our{" "}
                    <SignUpButton
                      type="button"
                      onClick={() => setOpenSignUpWindow(true)}
                    >
                      newsletter
                    </SignUpButton>{" "}
                    or join our{" "}
                    <DiscordLink
                      href="https://discord.gg/9DmsRyuc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Discord community
                    </DiscordLink>
                    !{" "}
                    <span
                      style={{ outline: "1px solid transparent" }}
                      className="smiley smiley--smile"
                    >
                      &nbsp;
                    </span>
                  </>
                )}
              </div>
            );
          })}
        </TabContent>
      </TabContentWrap>
      <TextFormatBar
        editor={editor}
        inlineStyle={inlineStyle}
        setInlineStyle={setInlineStyle}
        onKeyUp={handleSubmit}
      />
      <BuddyIconWrapper>
        <BuddyIconSection>
          <HoverImgButton
            img={WarnIcon}
            hoverImg={WarnHoverIcon}
            imgProps={{
              width: 22,
              height: 32,
            }}
            hoverImgProps={{
              width: 22,
              height: 33,
            }}
            buttonProps={{
              style: { padding: "0 5px" },
            }}
            alt="Warn"
          />
          <HoverImgButton
            img={BlockIcon}
            hoverImg={BlockHoverIcon}
            imgProps={{
              width: 24,
              height: 30,
            }}
            hoverImgProps={{
              width: 24,
              height: 31,
            }}
            buttonProps={{
              style: { padding: "0 5px" },
            }}
            alt="Block"
          />
        </BuddyIconSection>
        <VerticalDivider />
        <MiddleBottom>
          <HoverImgButton
            img={AddBuddyIcon}
            hoverImg={AddBuddyHoverIcon}
            imgProps={{
              width: 45,
              height: 32,
            }}
            hoverImgProps={{
              width: 45,
              height: 33,
            }}
            buttonProps={{
              style: { padding: "0 5px" },
            }}
            alt="Warn"
          />
          <HoverImgButton
            img={GetInfoIcon}
            hoverImg={GetInfoHoverIcon}
            imgProps={{
              width: 35,
              height: 30,
            }}
            buttonProps={{
              style: { padding: "0 5px" },
            }}
            alt="Get Info"
          />
        </MiddleBottom>
        <VerticalDivider />
        <BuddyIconSection>
          <Column>
            <HoverImgButton
              img={true ? SendActiveIcon : SendIcon}
              hoverImg={true ? SendHoverIcon : SendIcon}
              imgProps={{
                width: 30,
                height: 34,
              }}
              buttonProps={{
                style: { padding: "0 5px" },
              }}
              onClick={sendChat}
              alt="Send Message"
            />
            <ProgressBar warningLevel={0} />
          </Column>
        </BuddyIconSection>
      </BuddyIconWrapper>
      {openSignUpWindow &&
        ReactDOM.createPortal(
          <SignUpWindow onClose={() => setOpenSignUpWindow(false)} />,
          document.getElementById("modals") as HTMLElement
        )}
    </>
  );
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BuddyIconSection = styled.div`
  display: flex;
  justify-content: center;
`;
const MiddleBottom = styled.div`
  flex-grow: 1;
  display: flex;
  height: 100%;
  justify-content: space-evenly;
`;
const BuddyIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  box-shadow: inset -1px -1px #dfdfdf, inset 1px 1px grey;
  margin: 5px 0 2px;
  padding: 0 5px;
`;

const UsernameText = styled.span<{ isReceived: boolean }>`
  float: left;
  margin-right: 4px;
  color: ${(props) => (props.isReceived ? "#0000ff" : "#ff0000")};
`;

const TabContent = styled.div`
  position: relative;
  height: 102px;
  background-color: #fff;
  box-shadow: inset 2px 2px 1px #000;
  resize: none;
  font: inherit;
  appearance: none;
  padding: 6px;
  border: 0;
  border-radius: 0;
  font-family: Times New Roman, serif;
  font-size: 16px;
  overflow-y: auto;
  flex-grow: 1;
  &,
  & * {
    user-select: initial;
  }

  > div + div {
    margin-top: 2px;
  }

  p {
    margin: 0;
    padding: 1px 2px;
    line-height: 1.07;
    word-break: break-word;
  }

  &:focus {
    outline: none;
  }
`;
const TabContentWrap = styled.div`
  position: relative;
  background-color: #c0c0c0;
  box-shadow: 1px 1px 1px #030300;
  margin-top: 6px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  user-select: initial;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 16px);
    height: 100%;
    box-shadow: inset 2px 2px 1px #000;
    pointer-events: none;
  }
`;

const SignUpButton = styled.button`
  font: inherit;
  color: blue;
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
  }
  &:visited {
    color: purple;
  }
`;

const DiscordLink = styled.a`
  font: inherit;
  color: blue;
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
  }
  &:visited {
    color: purple;
  }
`;

export default MyEditor;
