import React, { useEffect, useRef, useState } from 'react';
import useSimpleClickOutside from '../useSimpleClickOutside';
import { generateUniqueId } from './utils';

type Window = { [key: string]: any } & { id: string };

type WindowObject = {
  element: HTMLDivElement | null;
  id: string;
};

export const WindowManagerContext = React.createContext({});

export const createWindowManager = () => {
  const useWindowManagerState = () => {
    const windowRefs = useRef<WindowObject[]>([]);

    // set up global state that we later add to the WindowManagerProvider;
    const [sortedOrder, setSortedOrder] = useState<Window[]>([]);

    // helper functions
    const focusWindowById = (
      matchingWindowId: string,
      isActive?: boolean,
      status?: string
    ) => {
      // @ts-ignore
      setSortedOrder((prevOrder: Window[]) => {
        // cherry pick the matching window from prevOrder
        const matchedWindow = prevOrder.find(
          ({ id }) => id === matchingWindowId
        );
        // add back at the end
        return [
          ...prevOrder
            .filter(({ id }) => id !== matchingWindowId)
            .map((prevWindow) => ({ ...prevWindow, isActive: false })),
          {
            ...matchedWindow,
            ...(status ? { status } : {}),
            isActive,
          },
        ];
      });
    };

    useSimpleClickOutside(
      windowRefs,
      (event: any) => {
        // when we click on a Window
        const matchingWindow = windowRefs.current.find(
          ({ element }) => element && element.contains(event.target)
        );
        const matchingWindowId = matchingWindow?.id;

        // change sort order here
        // @ts-ignore
        if (matchingWindowId) {
          focusWindowById(matchingWindowId, true);
        }
      },
      () => {
        // when you click outside a window
        setSortedOrder((prevOrder) =>
          prevOrder.map((window) => ({ ...window, isActive: false }))
        );
      }
    );

    // added to each Window component
    const useWindowRef = (data: any) => {
      const windowRef = useRef<HTMLDivElement>(null);
      const windowId = useRef(generateUniqueId()).current;

      // when each window is mounted
      useEffect(() => {
        // add to array of windowRefs
        windowRefs.current.push({ id: windowId, element: windowRef.current });
        // set sorted order of windows
        setSortedOrder((prevOrder) => {
          return [
            ...prevOrder.map((prevWindow) => ({
              ...prevWindow,
              isActive: false,
            })),
            {
              ...data,
              id: windowId,
              isActive: true,
              mountedOrder: prevOrder.length + 1,
              status: 'restore',
            },
          ];
        });

        // when each window is unmounted
        return () => {
          // update windowRefs to remove this ref
          windowRefs.current = windowRefs.current.filter(
            ({ id }) => windowId !== id
          );
          // update sorter order to remove this window
          setSortedOrder((prevOrder) => {
            return prevOrder.filter(({ id }) => windowId !== id);
          });
        };
      }, []);

      return { windowRef, windowId };
    };

    return {
      windowRefs,
      useWindowRef,
      sortedOrder,
      setSortedOrder,
      focusWindowById,
    };
  };

  return { WindowManagerProvider, useWindowManagerState };
};

export const WindowManagerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { WindowManagerProvider, useWindowManagerState } =
    createWindowManager();
  const windowState = useWindowManagerState();

  return (
    <WindowManagerContext.Provider value={windowState}>
      {children}
    </WindowManagerContext.Provider>
  );
};
