import React from "react";
import styled from "styled-components";
import Window from "./Window";
import DetailsSection from "./DetailsSection";
import { InlineStyles, setState } from "../types";

const basicColors = [
  "#ff8080",
  "#ffff80",
  "#80ff80",
  "#00ff80",
  "#80ffff",
  "#0080ff",
  "#ff80c0",
  "#ff80ff",
  "#ff0000",
  "#ffff00",
  "#80ff00",
  "#00ff40",
  "#00ffff",
  "#0080c0",
  "#8080c0",
  "#ff00ff",
  "#804040",
  "#ff8040",
  "#00ff00",
  "#008080",
  "#004080",
  "#8080ff",
  "#800040",
  "#ff0080",
  "#800000",
  "#ff8000",
  "#008000",
  "#008040",
  "#0000ff",
  "#0000a0",
  "#800080",
  "#8000ff",
  "#400000",
  "#804000",
  "#004000",
  "#004040",
  "#000080",
  "#000040",
  "#400040",
  "#400080",
  "#000000",
  "#808000",
  "#808040",
  "#808080",
  "#408080",
  "#c0c0c0",
  "#400041",
  "#ffffff",
];

interface Props {
  type: string;
  inlineStyle: InlineStyles;
  setInlineStyle: setState<boolean>;
  onClose: () => void;
  editor: any;
}

const ColorPicker = ({
  type,
  inlineStyle,
  setInlineStyle,
  onClose,
  editor,
}: Props) => {
  function handleOk() {
    editor
      .chain()
      .focus()
      .setColor(
        inlineStyle.color,
        inlineStyle.bgColor,
        inlineStyle.fontFamily,
        inlineStyle.fontSize
      )
      .run();
    onClose();
  }

  function handleCancel() {
    onClose();
  }

  function handleColorBlockClick(colorHex: string) {
    const colorType = type === "color" ? "color" : "bgColor";
    setInlineStyle((prevInlineStyle: InlineStyles) => ({
      ...prevInlineStyle,
      [colorType]: colorHex,
    }));
  }

  return (
    <Window title="Color" navActionList={["close"]} onClose={handleCancel}>
      <DetailsSection title="Basic Colors">
        <Flex>
          {basicColors.map((colorHex) => (
            <ColorBlock
              key={colorHex}
              color={colorHex}
              onClick={() => handleColorBlockClick(colorHex)}
            />
          ))}
        </Flex>
      </DetailsSection>
      <ActionsWrap>
        <ActionButton onClick={handleOk}>OK</ActionButton>
        <ActionButton onClick={handleCancel}>Cancel</ActionButton>
      </ActionsWrap>
    </Window>
  );
};

const ActionsWrap = styled.div`
  width: 100%;
  display: flex;
  margin: 0 8px;
`;
const ActionButton = styled.button`
  margin: 0px 4px 8px;
  min-width: 48px;
  outline-width: 1px;
  outline-offset: -5px;
  padding: 5px 1px;
  box-shadow: inset -1px -1px 0px #0c0c0c, inset 1px 1px 0px #ffffff,
    inset -2px -2px 0px #808088, inset 2px 2px 0px #bbc3c4;
  border: 0px solid transparent;
  background-color: #bbc3c4;
  position: relative;
  user-select: none;
  font: inherit;
  appearance: none;

  &:focus {
    outline: 1px dotted #000;
    outline-offset: -4px;
  }
`;
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 200px;
`;
const ColorBlock = styled.button`
  width: 19px;
  height: 19px;
  background-color: ${(props) => props.color};
  appearance: none;
  margin: 3px;
  padding: 0;
  border: 0;
  appearance: none;

  &:focus {
    outline: 1px dotted #000;
    outline-offset: 1px;
  }
`;

export default ColorPicker;
