import React, { useState } from 'react';
import AIMProvider from './AIMProvider';
import { WindowManagerProvider } from './react-window-manager';

const AppContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <WindowManagerProvider>
      <AIMProvider>{children}</AIMProvider>
    </WindowManagerProvider>
  );
};

export default AppContainer;
