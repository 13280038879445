import React, { useContext } from "react";
import styled from "styled-components";
import Window from "./Window";
import Checkbox from "./FormCheckbox/Checkbox";
import SelectBox from "./FormSelectBox/SelectBox";
import DetailsSection from "./DetailsSection/DetailsSection";
import Radio from "./FormRadio/Radio";
import DefaultBuddyIconImage from "../images/default_buddy_icon.png";
import AIMHeaderIconOg from "../images/aim_header_icon.png";
import AIMHeaderIcon from "../images/aim_header_icon_new.png";
import { BuddyIconGrid } from "./BuddyIconGrid";
import ButtonForm from "./ButtonForm";
import { AIMContext } from "../AIMProvider";

const options = [
  {
    title: "Buddy Icons",
    value: "Buddy Icons",
    isSelected: true,
  },
];

const imageOptions = [
  {
    title: "*My Buddy Icon*",
    value: "*My Buddy Icon*",
    isSelected: "*My Buddy Icon*" === "*My Buddy Icon*",
  },
];

function PreferencesMenu({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { buddyIconSrc, debugMode, username } = useContext(AIMContext);
  return (
    <Window
      title={`${username}'s AOL Instant Messenger Preferences`}
      navActionList={["close"]}
      onClose={() => setOpen(false)}
      icon={debugMode ? AIMHeaderIconOg : AIMHeaderIcon}
    >
      <Wrapper>
        <Flex>
          <CategorySection>
            <Subtitle>Category</Subtitle>
            <SelectBoxFancy
              /* @ts-ignore */
              options={options}
              onClick={() => {}}
              selectedOption="Buddy Icons"
            />
          </CategorySection>
          <PanelRight>
            <PanelRightTop>
              <BuddyIconSection>
                <strong>Buddy Icons</strong>
                <ImagesSection>
                  <DetailsSectionElem title="Images to use for buddies">
                    <ImagesSelectBox
                      /* @ts-ignore */
                      options={imageOptions}
                      onClick={() => {}}
                      selectedOption="*My Buddy Icon*"
                      // selectedOption={currentFont}
                    />
                    <DisplaySection>
                      Your Icon to send to buddies:
                      <DefaultIconWrap>
                        <DefaultBuddyIcon
                          src={buddyIconSrc || DefaultBuddyIconImage}
                          alt=""
                          width="48"
                          height="48"
                        />
                        <ButtonWrap>
                          {/* <ActionButton
                            width="100%"
                            onClick={() => {
                              setOpen(false);
                            }}
                            disabled
                          >
                            Browse PC...
                          </ActionButton> */}
                          <ButtonForm
                            width="100%"
                            onClick={() => {
                              setOpen(false);
                            }}
                            disabled
                            isDisabled
                          >
                            Browse PC...
                          </ButtonForm>
                          <ActionButton
                            width="100%"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            Clear
                          </ActionButton>
                        </ButtonWrap>
                      </DefaultIconWrap>
                      <ActionButton width="100%" onClick={() => {}}>
                        Clear all Buddy Icons
                      </ActionButton>
                    </DisplaySection>
                  </DetailsSectionElem>
                </ImagesSection>
                <DetailsSectionElem title="Display Buddy Icons">
                  <RadioWrap>
                    <RadioInnerWrap>
                      <Radio label="Accept from users on Buddy List" checked />
                      <CheckboxCustom
                        label="Display Buddy Icon Knock-Knock dialog"
                        disabled
                        isDisabled
                      />
                    </RadioInnerWrap>
                    <Radio label="Never accept Buddy Icons" />
                    <Radio label="Never display Buddy Icons" />
                  </RadioWrap>
                </DetailsSectionElem>
              </BuddyIconSection>
              <BuddyIconListSection>
                <BuddyIconSelect>
                  <BuddyIconGrid />
                </BuddyIconSelect>
              </BuddyIconListSection>
            </PanelRightTop>
            <ActionsWrap>
              <ActionButton
                width="72px"
                onClick={() => {
                  setOpen(false);
                }}
              >
                OK
              </ActionButton>
              <ActionButton width="72px" onClick={() => {}}>
                Cancel
              </ActionButton>
              <ActionButton width="72px" onClick={() => {}}>
                Apply
              </ActionButton>
            </ActionsWrap>
          </PanelRight>
        </Flex>
      </Wrapper>
    </Window>
  );
}

const RadioInnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const PanelRightTop = styled.div`
  display: flex;
`;
const PanelRight = styled.div`
  display: flex;
  flex-direction: column;
`;
const BuddyIconListSection = styled.div``;
const CheckboxCustom = styled(Checkbox)`
  label span {
    display: inline-flex;
    width: 65%;
    text-align: left;
    vertical-align: top;
  }
`;
const RadioWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  margin-top: 6px;
`;
const ButtonWrap = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const DefaultIconWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
`;
const DefaultBuddyIcon = styled.img``;
const DetailsSectionElem = styled(DetailsSection)`
  width: 100%;
  text-align: center;

  line-height: 40px;
  margin-left: 0;

  .is-active {
    background-color: #050283;
  }

  > div {
    line-height: normal;
  }
`;
const ImagesSection = styled.div``;
const DisplaySection = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;
const Subtitle = styled.p`
  margin: 0 0 4px;
`;
const Flex = styled.div`
  display: flex;
`;
const BuddyIconSection = styled.div`
  width: 188px;
  margin-right: 8px;
`;
const SelectBoxFancy = styled(SelectBox)`
  height: 375px;
  width: 80px;
  //   > div {
  //     height: 375px;
  //     width: 80px;
  //   }
  button {
    font: inherit;
    padding: 4px;
  }
`;
const BuddyIconSelect = styled.div`
  position: relative;
  background-color: #fff;
  margin: 3px;
  box-shadow: inset 2px 2px 1px #000;
  width: 252px;
  height: 339px;
  padding: 0;
  margin-top: 27px;
  overflow-y: auto;
`;
const ImagesSelectBox = styled(SelectBox)`
  margin-top: 6px;
  > div {
    width: 170px;
    height: 83px;
    overflow-y: scroll;
  }
  button {
    font: inherit;
    padding: 4px;
  }
`;
const CategorySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 11px;
`;
const ActionsWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  gap: 8px;
`;
const ActionButton = styled.button<{ width?: string }>`
  min-width: 48px;
  outline-width: 1px;
  outline-offset: -5px;
  padding: 5px 16px;
  box-shadow: inset -1px -1px 0px #0c0c0c, inset 1px 1px 0px #ffffff,
    inset -2px -2px 0px #808088, inset 2px 2px 0px #bbc3c4;
  border: 0px solid transparent;
  background-color: #bbc3c4;
  position: relative;
  user-select: none;
  font: inherit;
  appearance: none;
  width: ${(props) => props.width};

  &:focus {
    outline: 1px dotted #000;
    outline-offset: -4px;
  }
`;
const Wrapper = styled.div`
  padding: 6px;
`;

export default PreferencesMenu;
