import React, { useContext, useEffect, useState } from 'react';
import { WindowManagerContext } from './react-window-manager';

interface Window {
  title: string;
  icon: React.ReactNode;
  width?: number;
  height?: number;
}

interface AwayMessage {
  json: any;
  text: string;
}

interface InitialValues {
  loginStatus: string;
  setLoginStatus: Function;
  username: string;
  setUsername: Function;
  awayMessage: AwayMessage | null;
  setAwayMessage: Function;
  openChatWindows: any[] | null;
  setOpenChatWindows: Function;
  soundEffects: any[] | null;
  setSoundEffects: Function;
  mountedOrder: any[] | null;
  debugMode: boolean;
  skipLogin: boolean;
  buddyIconSrc: string;
  setBuddyIconSrc: Function;
}

export const AIMContext = React.createContext({
  loginStatus: '',
  setLoginStatus() {},
  username: '',
  setUsername() {},
  awayMessage: null,
  setAwayMessage() {},
  openChatWindows: null,
  setOpenChatWindows() {},
  soundEffects: [],
  setSoundEffects() {},
  mountedOrder: [],
  debugMode: false,
  skipLogin: false,
  buddyIconSrc: '',
  setBuddyIconSrc() {},
} as InitialValues);

export const AIMProvider = ({ children }: { children: React.ReactNode }) => {
  const [loginStatus, setLoginStatus] = useState('');
  const [username, setUsername] = useState('');
  const [awayMessage, setAwayMessage] = useState(null);
  const [openChatWindows, setOpenChatWindows] = useState<string[]>([]);
  const [soundEffects, setSoundEffects] = useState([]);
  const [debugMode, setDebugMode] = useState(false);
  const [skipLogin, setSkipLogin] = useState(false);
  const [buddyIconSrc, setBuddyIconSrc] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.get('debug') === 'true') {
      setDebugMode(true);
    }
    if (params.get('skipLogin') === 'true') {
      setSkipLogin(true);
      setUsername('Guest');
    }
  }, []);

  const windowState = useContext(WindowManagerContext);
  // @ts-ignore
  const { sortedOrder } = windowState || {};

  const mountedOrder = [...sortedOrder].sort((a, b) =>
    a.mountedOrder > b.mountedOrder ? 1 : -1
  );
  return (
    <AIMContext.Provider
      value={{
        loginStatus,
        setLoginStatus,
        username,
        setUsername,
        awayMessage,
        setAwayMessage,
        openChatWindows,
        setOpenChatWindows,
        soundEffects,
        setSoundEffects,
        mountedOrder,
        debugMode,
        skipLogin,
        buddyIconSrc,
        setBuddyIconSrc,
      }}
    >
      {children}
    </AIMContext.Provider>
  );
};

export default AIMProvider;
